import React, { Component } from 'react';
import axios from 'axios';
import NumericInput from 'react-numeric-input';
import * as RestApi from "../../modules/RestApi";
import SubscriptionCostCalc from "../../modules/SubscriptionCostCalc";
import { Collapse } from 'reactstrap';
import NumberFormat from "react-number-format";
import {Form, InputGroup, Modal} from 'react-bootstrap'


class SubscriptionModalForm extends Component {
  constructor(props) {
    super(props);
    let maxLabelCharacters = 15;
    this.state = {
      validated: false,
      title: "Add",
      maxLabelCharacters: maxLabelCharacters,
      trialPriceLabelChars: maxLabelCharacters,
      trialShippingPriceLabelChars: maxLabelCharacters,
      subscriptionPriceLabelChars: maxLabelCharacters,
      subscriptionPriceShippingLabelChars: maxLabelCharacters,
      monthlyCostLabelChars: maxLabelCharacters - "Monthly".length,
      firstYearCostLabelChars: maxLabelCharacters - "1st Year".length,
      annualCostLabelChars: maxLabelCharacters - "Annually".length,
      monthlyCostError: "",
      annualCostError: "",
      firstYearCostError: "",
      trialCollapse: false,
      subscription: {
        publishYN: true,
        subscriptionResearchDate: new Date(),
        subscriptionCurrency: "USD",
        companyName: "",
        subscriptionName: "",
        subscriptionDescription: "",
        subscriptionURL: "",
        trialYN: false,
        trialPrice: 0,
        trialDurationUnits: 1,
        trialDurationPeriod: "",
        trialShippingFeeYN: false,
        trialShippingFee: 0,
        trialShippingFeeLabel: "",
        trialTaxFeesYN: false,
        trialTotalCosts: 0,
        subscriptionPrice: 0,
        subscriptionPaymentFrequency: "",
        subscriptionPaymentFrequencyUnits: 1,
        subscriptionPriceLabel: "",
        shippingFeeYN: false,
        shippingFee: 0,
        shippingFeeLabel: "",
        subscriptionTotalCosts: 0,
        subscriptionTaxFeesYN: false,
        monthlyCost: 0,
        monthlyCostLabel: "Monthly",
        monthlyDetailedCostLabel: "",
        firstYearCost: 0,
        firstYearCostLabel: "1st Year",
        firstYearDetailedCostLabel: "",
        annualCost: 0,
        annualCostLabel: "2nd Year+",
        annualDetailedCostLabel: "",
      }
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.trialToggle = this.trialToggle.bind(this);
    this.completeManualAdd = this.completeManualAdd.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
  }
  trialToggle() {
    this.setState({ trialCollapse: !this.state.trialCollapse });
  }
  cancelForm() {
    this.props.hideSubscriptionForm();
  }
  handleFormReset() {
    this.setState({
      trialPriceLabelChars: this.state.maxLabelCharacters,
      trialShippingPriceLabelChars: this.state.maxLabelCharacters,
      subscriptionPriceLabelChars: this.state.maxLabelCharacters,
      subscriptionPriceShippingLabelChars: this.state.maxLabelCharacters,
      monthlyCostLabelChars: this.state.maxLabelCharacters,
      firstYearCostLabelChars: this.state.maxLabelCharacters,
      annualCostLabelChars: this.state.maxLabelCharacters,
      monthlyCostError: "",
      annualCostError: "",
      firstYearCostError: "",
      subscription: {
        publishYN: false,
        subscriptionResearchDate: new Date("2019-01-27T05:00:00.000Z"),
        subscriptionCurrency: "USD",
        companyName: "",
        subscriptionName: "",
        subscriptionDescription: "",
        subscriptionURL: "",
        trialYN: false,
        trialPrice: 0,
        trialDurationUnits: 0,
        trialDurationPeriod: "",
        trialPriceLabel: "",
        trialShippingFeeYN: false,
        trialShippingFee: 0,
        trialShippingFeeLabel: "",
        trialTaxFeesYN: false,
        trialTotalCosts: 0,
        subscriptionPrice: 0,
        subscriptionPriceLabel: "",
        subscriptionPaymentFrequency: "",
        subscriptionPaymentFrequencyUnits: 1,
        subscriptionTaxFeesYN: false,
        shippingFeeYN: false,
        shippingFee: 0,
        shippingFeeLabel: "",
        subscriptionTotalCosts: 0,
        monthlyCost: 0,
        monthlyCostLabel: "",
        monthlyDetailedCostLabel: "",
        firstYearCost: 0,
        firstYearCostLabel: "",
        firstYearDetailedCostLabel: "",
        annualCost: 0,
        annualCostLabel: "",
        annualDetailedCostLabel: "",
      }
    });
  }
  validateForm(event) {
    const form = event.currentTarget;
    if (this.state.subscription.trialYN && this.state.subscription.trialDurationPeriod === "") {
      this.refs.trialDurationPeriod.setCustomValidity('Please select a time period.');
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.completeManualAdd();
    }
    this.setState({ validated: true });
  }
  completeManualAdd() {
    console.log("Getting Subscription ID");
    let method = "get";
    const getSubscriptionIDUrl = RestApi.API_BASE + RestApi.PATH_SUBSCRIPTION_ID;
    axios({
      method: method,
      url: getSubscriptionIDUrl,
      withCredentials: true
    })
      .then((result) => {
        this.setState({
          subscription: {
            ...this.state.subscription,
            id: result.data
          }
        }, () => {
          console.log("Adding Manual Entry Subscription to Calculator");
          this.props.addSubscriptionToCalc(this.state.subscription);
          this.props.hideSubscriptionForm();
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.name !== undefined && error.response.data.name === 'RequestError') {
            this.setState({ savedCalculator: null });
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        }
      });

  }
  handleDateChange(date) {
    this.setState({subscriptionResearchDate: date});
  }
  handleFieldChange(event) {
    switch(event.target.id) {
      case "subscriptionCurrency":
        this.setState({subscription: {...this.state.subscription, subscriptionCurrency: event.target.value}});
        break;
      case "companyName":
        this.setState({subscription: {...this.state.subscription, companyName: event.target.value}});
        break;
      case "subscriptionName":
        this.setState({subscription: {...this.state.subscription, subscriptionName: event.target.value}});
        break;
      case "subscriptionDescription":
        this.setState({subscription: {...this.state.subscription, subscriptionDescription: event.target.value}});
        break;
      case "subscriptionURL":
        this.setState({subscription: {...this.state.subscription, subscriptionURL: event.target.value}});
        break;
      case "trialYN":
        this.trialToggle();
        this.setState({
          subscription: {
            ...this.state.subscription,
            trialYN: !this.state.subscription.trialYN
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "trialDurationPeriod":
        this.setState({
          subscription: {
            ...this.state.subscription,
            trialDurationPeriod: event.target.value
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "trialShippingFeeYN":
        this.setState({subscription: {
            ...this.state.subscription,
            trialShippingFeeYN: !this.state.subscription.trialShippingFeeYN
          }}, () => {
          if (this.state.subscription.trialShippingFeeYN) {
            this.setState({
              subscription: {
                ...this.state.subscription,
                trialTotalCosts: this.state.subscription.trialPrice + this.state.subscription.trialShippingFee
              }}, () => {
              this.calculateAllPrices();
            })
          } else {
            this.setState({
              subscription: {
                ...this.state.subscription,
                trialTotalCosts: this.state.subscription.trialPrice
              }}, () => {
              this.calculateAllPrices();
            })
          }
        });
        break;
      case "trialTaxFeesYN":
        this.setState({subscription: {...this.state.subscription, trialTaxFeesYN: !this.state.subscription.trialTaxFeesYN}});
        break;
      case "trialTotalCosts":
        this.setState({subscription: {...this.state.subscription, trialTotalCosts: this.state.subscription.trialPrice + this.state.subscription.trialShippingFee}});
        break;
      case "subscriptionPaymentFrequency":
        this.setState({
          subscription: {
            ...this.state.subscription,
            subscriptionPaymentFrequency: event.target.value
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "shippingFeeYN":
        this.setState({
          subscription: {
            ...this.state.subscription,
            shippingFeeYN: !this.state.subscription.shippingFeeYN,
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "subscriptionTaxFeesYN":
        this.setState({subscription: {...this.state.subscription, subscriptionTaxFeesYN: !this.state.subscription.subscriptionTaxFeesYN}});
        break;
      case "subscriptionTotalCosts":
        this.setState({subscription: {...this.state.subscription, subscriptionTotalCosts: this.state.subscription.subscriptionPrice + this.state.subscription.shippingFee}});
        break;
      default:
        break;
    }
  }
  handleFrequencyChange(value, valueString, element) {
    switch(element.id) {
      case "trialDurationUnits":
        this.setState({
          subscription: {
            ...this.state.subscription,
            trialDurationUnits: value
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "subscriptionPaymentFrequencyUnits":
        this.setState({subscription: {...this.state.subscription,
            subscriptionPaymentFrequencyUnits: value,
            shippingFeeLabel: value
          }}, () => {
          this.calculateAllPrices();
        });
        break;
      default:
        break;
    }
  }
  handlePriceChange(value, valueString, element) {
    let trialTotalCosts = 0;
    let subscriptionTotalCosts = 0;

    switch(element.id) {
      case "trialPrice":
        if ((this.state.subscription.trialShippingFee != null || this.state.subscription.trialShippingFee !== '' ) && this.state.subscription.trialShippingFeeYN) {
          trialTotalCosts = value + this.state.subscription.trialShippingFee;
        } else {
          trialTotalCosts = value;
        }
        this.setState({
          subscription: {
            ...this.state.subscription,
            trialPrice: value,
            trialTotalCosts: trialTotalCosts
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "trialShippingFee":
        if (value != null && this.state.subscription.trialShippingFeeYN) {
          trialTotalCosts = this.state.subscription.trialPrice + value;
        } else {
          trialTotalCosts = this.state.subscription.trialPrice;
        }
        this.setState({
          subscription: {
            ...this.state.subscription,
            trialShippingFee: value,
            trialTotalCosts: trialTotalCosts
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "subscriptionPrice":
        if (this.state.subscription.shippingFee != null && this.state.subscription.shippingFeeYN) {
          subscriptionTotalCosts = value + this.state.subscription.shippingFee;
        } else {
          subscriptionTotalCosts = value;
        }
        this.setState({
          subscription: {
            ...this.state.subscription,
            subscriptionPrice: value,
            subscriptionTotalCosts: subscriptionTotalCosts
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "shippingFee":
        if (value != null && this.state.subscription.shippingFeeYN) {
          subscriptionTotalCosts = this.state.subscription.subscriptionPrice + value;
        } else {
          subscriptionTotalCosts = this.state.subscription.subscriptionPrice;
        }
        this.setState({
          subscription: {
            ...this.state.subscription,
            shippingFee: value,
            subscriptionTotalCosts: subscriptionTotalCosts
          }
        }, () => {
          this.calculateAllPrices();
        });
        break;
      case "monthlyCost":
        this.setState({subscription: {...this.state.subscription, monthlyCost: value}});
        break;
      case "firstYearCost":
        this.setState({subscription: {...this.state.subscription, firstYearCost: value}});
        break;
      case "annualCost":
        this.setState({subscription: {...this.state.subscription, annualCost: value}});
        break;
      default:
        break;
    }
  }
  calculateAllPrices() {
    const calculator = new SubscriptionCostCalc(this.state.subscription);
    let subscriptionTotalCosts;
    if (this.state.subscription.shippingFeeYN) {
      subscriptionTotalCosts = this.state.subscription.subscriptionPrice + this.state.subscription.shippingFee;
    } else {
      subscriptionTotalCosts = this.state.subscription.subscriptionPrice;
    }
    this.setState((prevState) => ({
      subscription: {
        ...prevState.subscription,
        monthlyCost: calculator.monthlyCost,
        monthlyCostLabel: calculator.monthlyCostLabel,
        monthlyDetailedCostLabel: calculator.monthlyDetailedCostLabel,
        firstYearCost: calculator.firstYearCost,
        firstYearCostLabel: calculator.firstYearCostLabel,
        firstYearDetailedCostLabel: calculator.firstYearDetailedCostLabel,
        annualCost: calculator.yearlyCost,
        annualCostLabel: calculator.annualCostLabel,
        annualDetailedCostLabel: calculator.annualDetailedCostLabel,
        subscriptionPriceLabel: calculator.subscriptionPriceLabel,
        subscriptionTotalCosts: subscriptionTotalCosts
      }
    }));
  }
  render() {
    const { validated } = this.state;
    return (
      <div className="container-fluid">
        <Form
          noValidate
          validated={validated}
          onSubmit={e => this.validateForm(e)}
          className="form">
          <Form.Row>
            <Form.Group className="form-group col-md-6 mb-1">
              <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="companyName">Company Name*</Form.Label>
              <Form.Control required value={this.state.subscription.companyName} onChange={this.handleFieldChange} type="search" className="form-control form-control-sm" id="companyName" placeholder="For example, Netflix" />
              <Form.Control.Feedback type="invalid">
                Please enter a name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group col-md-6 mb-1">
              <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="subscriptionName">Subscription Name*</Form.Label>
              <Form.Control required value={this.state.subscription.subscriptionName} onChange={this.handleFieldChange} type="search" className="form-control form-control-sm" id="subscriptionName" placeholder="For example, Basic Plan" />
              <Form.Control.Feedback type="invalid">
                Please enter a name.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-group col-md-12 mb-1">
              <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="description">Subscription Description</Form.Label>
              <Form.Control
                as="textarea"
                value={this.state.subscription.subscriptionDescription}
                onChange={this.handleFieldChange}
                className="form-control form-control-sm"
                id="subscriptionDescription"
                placeholder="For example, describe the features, limits, capabilities of the subscription."
                rows="2"/>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-group col-md-12 mb-1">
              <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="subscriptionURL">Subscription URL</Form.Label>
              <Form.Control value={this.state.subscription.subscriptionURL} onChange={this.handleFieldChange} type="search" className="form-control form-control-sm" id="subscriptionURL" placeholder="For example, http://www.netflix.com/plan" />
            </Form.Group>
          </Form.Row>
          <Form.Row className="form-row mt-3">
            <Form.Group className="form-group col-md-4 mb-1">
              <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="subscriptionPrice">Subscription Price</Form.Label>
              <InputGroup className="input-group input-group-sm">
                <InputGroup.Prepend className="input-group-prepend">
                  <InputGroup.Text className="input-group-text">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumericInput
                  step={1.0}
                  precision={2}
                  required
                  min={1}
                  value={this.state.subscription.subscriptionPrice}
                  className="form-control form-control-sm field-no-left-radius"
                  onChange={this.handlePriceChange}
                  id="subscriptionPrice"
                  placeholder="e.g. $9.99"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="form-group col-md-3 mb-1">
              <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="subscriptionCurrency">Currency</Form.Label>
              <Form.Control value={this.state.subscription.subscriptionCurrency} onChange={this.handleFieldChange} type="search" className="form-control form-control-sm" id="subscriptionCurrency" placeholder="" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-group col-md-4 mb-1">
              <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="subscriptionPrice">How Often You Pay, Every*:</Form.Label>
              <NumericInput
                step={1.0}
                precision={0}
                min={1}
                value={this.state.subscription.subscriptionPaymentFrequencyUnits}
                className="form-control form-control-sm field-no-left-radius"
                onChange={this.handleFrequencyChange}
                id="subscriptionPaymentFrequencyUnits"
                placeholder="e.g. number of months"
              />
            </Form.Group>
            <Form.Group className="form-group col-md-4 mb-1">
              <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="subscriptionPaymentFrequency">&nbsp;</Form.Label>
              <Form.Control required as="select" onChange={this.handleFieldChange} value={this.state.subscription.subscriptionPaymentFrequency} className="form-control form-control-sm" id="subscriptionPaymentFrequency">
                <option value="">Select a time period</option>
                <option value="Hour(s)">Hour(s)</option>
                <option value="Day(s)">Day(s)</option>
                <option value="Week(s)">Week(s)</option>
                <option value="Month(s)">Month(s)</option>
                <option value="Quarter(s)">Quarter(s)</option>
                <option value="Year(s)">Year(s)</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a time period.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row className="mt-3">
            <div className="col-md-12">
              <Form.Check
                checked={this.state.subscription.shippingFeeYN}
                onChange={this.handleFieldChange}
                className="col-form-label-sm font-weight-bold"
                label="Shipping Fees"
                type="checkbox"
                id="shippingFeeYN" />
              <InputGroup className="input-group input-group-sm">
                <InputGroup.Prepend className="input-group-prepend">
                  <InputGroup.Text className="input-group-text">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumericInput
                  disabled={this.state.subscription.shippingFeeYN ? false : true}
                  step={1.0}
                  precision={2}
                  min={0}
                  value={this.state.subscription.shippingFee}
                  className="form-control form-control-sm field-no-left-radius"
                  onChange={this.handlePriceChange}
                  id="shippingFee"
                  placeholder=""
                />
              </InputGroup>
            </div>
          </Form.Row>
          <Form.Row className="form-row mt-3">
            <div className="col-md-12">
              <Form.Check
                checked={this.state.subscription.subscriptionTaxFeesYN}
                onChange={this.handleFieldChange}
                className="col-form-label-sm font-weight-bold"
                label="Taxes and other fees included in subscription price"
                type="checkbox"
                id="subscriptionTaxFeesYN" />
            </div>
          </Form.Row>
          <div className="fieldGroup my-3">
            <Form.Row>
              <div className="col-md-12">
                <Form.Check
                  checked={this.state.subscription.trialYN}
                  onChange={this.handleFieldChange}
                  className="col-form-label-sm font-weight-bold"
                  label="Trial Available"
                  type="checkbox"
                  ref="trialYN"
                  id="trialYN" />
              </div>
            </Form.Row>
            <Collapse isOpen={this.state.trialCollapse}>
              <Form.Row>
                <Form.Group className="form-group col-md-7 mb-1">
                  <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="trialPrice">Trial Price</Form.Label>
                  <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <NumericInput
                      disabled={this.state.subscription.trialYN === true ? false : true}
                      step={1.0}
                      precision={2}
                      min={0}
                      value={this.state.subscription.trialPrice}
                      className="form-control form-control-sm field-no-left-radius"
                      onChange={this.handlePriceChange}
                      id="trialPrice"
                      placeholder="e.g. $9.99"
                    />
                  </div>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group className="form-group col-md-3 mb-1">
                  <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="trialDurationUnits">Trial Duration*</Form.Label>
                  <NumericInput
                    disabled={this.state.subscription.trialYN ? false : true}
                    step={1.0}
                    precision={0}
                    min={1}
                    value={this.state.subscription.trialDurationUnits}
                    className="form-control form-control-sm field-no-left-radius"
                    onChange={this.handleFrequencyChange}
                    id="trialDurationUnits"
                    placeholder="e.g. number of months"
                  />
                </Form.Group>
                <Form.Group className="form-group col-md-4">
                  <Form.Label className="col-form-label col-form-label-sm font-weight-bold" htmlFor="trialDurationPeriod">&nbsp;</Form.Label>
                  <Form.Control ref="trialDurationPeriod" as="select" disabled={this.state.subscription.trialYN ? false : true} onChange={this.handleFieldChange} value={this.state.subscription.trialDurationPeriod} className="form-control form-control-sm" id="trialDurationPeriod">
                    <option value="">Select a time period</option>
                    <option value="Hour(s)">Hour(s)</option>
                    <option value="Day(s)">Day(s)</option>
                    <option value="Week(s)">Week(s)</option>
                    <option value="Month(s)">Month(s)</option>
                    <option value="Quarter(s)">Quarter(s)</option>
                    <option value="Year(s)">Year(s)</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a time period.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <div className="col-md-12">
                  <Form.Check
                    checked={this.state.subscription.trialShippingFeeYN}
                    onChange={this.handleFieldChange}
                    className="col-form-label-sm font-weight-bold"
                    label="Trial Shipping Fees"
                    type="checkbox"
                    id="trialShippingFeeYN" />
                  <InputGroup className="input-group input-group-sm">
                    <InputGroup.Prepend className="input-group-prepend">
                      <InputGroup.Text className="input-group-text">$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <NumericInput
                      disabled={this.state.subscription.trialYN && this.state.subscription.trialShippingFeeYN ? false : true}
                      step={1.0}
                      precision={2}
                      min={0}
                      value={this.state.subscription.trialShippingFee}
                      className="form-control form-control-sm field-no-left-radius"
                      onChange={this.handlePriceChange}
                      id="trialShippingFee"
                      placeholder=""
                    />
                  </InputGroup>
                </div>
              </Form.Row>
              <Form.Row>
                <div className="col-md-12 mt-3">
                  <Form.Check
                    disabled={this.state.subscription.trialYN ? false : true}
                    checked={this.state.subscription.trialTaxFeesYN}
                    onChange={this.handleFieldChange}
                    className="col-form-label-sm font-weight-bold"
                    label="Taxes and other fees included in trial price"
                    type="checkbox"
                    id="trialTaxFeesYN" />
                </div>
              </Form.Row>
            </Collapse>
          </div>
          <Form.Row className="row">
            <div className="col-md-3 text-right">
              <div>
                <div className="font-weight-bold">Subscription Price<br/></div>
                <NumberFormat
                  value={this.state.subscription.subscriptionTotalCosts}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                />
              </div>
              <div className="small-text-muted">
                {this.state.subscription.subscriptionPriceLabel}<br/>
                {this.state.subscription.shippingFeeYN ? 'With Shipping' : ''}
              </div>
            </div>
            <div className="col-md-3 text-right">
              <div>
                <div className="font-weight-bold">Monthly Cost<br/></div>
                {this.state.subscription.monthlyCost !== undefined &&
                <NumberFormat
                  value={this.state.subscription.monthlyCost}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                />
                }
                {this.state.subscription.monthlyCost === null &&
                <span className="red">Missing</span>
                }
              </div>
              <div className="small-text-muted">
                {this.state.subscription.monthlyCostLabel}<br/>
                {this.state.subscription.monthlyDetailedCostLabel}
              </div>
            </div>
            <div className="col-md-3 text-right">
              <div>
                <div className="font-weight-bold">First Year Cost<br/></div>
                {this.state.subscription.firstYearCost !== undefined &&
                <NumberFormat
                  value={this.state.subscription.firstYearCost}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                />
                }
                {this.state.subscription.firstYearCost === null &&
                <span className="red">Missing</span>
                }
              </div>
              <div className="small-text-muted">
                {this.state.subscription.firstYearCostLabel}<br/>
                {this.state.subscription.firstYearDetailedCostLabel}
              </div>
            </div>
            <div className="col-md-3 text-right">
              <div>
                <div className="font-weight-bold">Annual Cost<br/></div>
                {this.state.subscription.annualCost !== undefined &&
                <NumberFormat
                  value={this.state.subscription.annualCost}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                />
                }
                {this.state.subscription.annualCost === null &&
                <span className="red">Missing</span>
                }
              </div>
              <div className="small-text-muted">
                {this.state.subscription.annualCostLabel}<br/>
                {this.state.subscription.annualDetailedCostLabel}
              </div>
            </div>
          </Form.Row>
          {!this.state.showSubscriptionForm &&
          <Modal.Footer className="negative-mx-15 mt-1 light-gray-background">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-right">
                  <button type="button" onClick={this.cancelForm} className="mx-3 btn btn-link">Cancel</button>
                  <button type="submit" className="btn btn-primary btn-primary-sm">Save</button>
                </div>
              </div>
            </div>
          </Modal.Footer>
          }
        </Form>
      </div>
    );
  }
}
export default SubscriptionModalForm;
