import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MainNavBar extends Component {

  render() {
    return (
      <nav className="navbar navbar-expand-sm navbar-dark navbar-background main-nav">
        <span className="green"><FontAwesomeIcon icon="calculator" /> <strong className="light-gray ml-1">Subscription Cost Calculator</strong></span>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">&nbsp;
        </div>
      </nav>
    );
  }
}

export default MainNavBar;
