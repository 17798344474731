
class SubscriptionCostCalc {

  hourlyCost = 0;
  dailyCost = 0;
  weeklyCost = 0;
  monthlyCost = 0;
  quarterlyCost = 0;
  yearlyCost = 0;
  totalCostsNormalized = 0;
  subscription = {};
  subscriptionPriceLabel = "";
  subscriptionDetailedPriceLabel = "";
  trialPriceLabel = "";
  monthlyCostLabel = "";
  monthlyDetailedCostLabel = "";
  firstYearCostLabel = "";
  firstYearDetailedCostLabel = "";
  annualCostLabel = "";
  annualDetailedCostLabel = "";

  constructor(subscription) {
    this.subscription = subscription;
    this.calculateCosts();
    this.calculateFirstYearCosts();
    this.setSubscriptionPriceLabel();
    this.setSubscriptionDetailedPriceLabel();
    this.setTrialPriceLabel();
    this.setMonthlyCostLabel();
    this.setMonthlyDetailedCostLabel();
    this.setFirstYearCostLabel();
    this.setFirstYearDetailedCostLabel();
    this.setAnnualCostLabel();
    this.setAnnualDetailedCostLabel();
  }

  setSubscriptionPriceLabel() {
    switch(this.subscription.subscriptionPaymentFrequency) {
      case "Hours(s)":
        if (parseInt(this.subscription.subscriptionPaymentFrequencyUnits, 10) === 1) {
          this.subscriptionPriceLabel = "Hourly";
        } else {
          this.subscriptionPriceLabel = this.subscription.subscriptionPaymentFrequencyUnits + " Hours";
        }
        break;
      case "Day(s)":
        if (parseInt(this.subscription.subscriptionPaymentFrequencyUnits, 10) === 1) {
          this.subscriptionPriceLabel = "Daily";
        } else {
          this.subscriptionPriceLabel = "Every " + this.subscription.subscriptionPaymentFrequencyUnits + " Days";
        }
        break;
      case "Week(s)":
        if (parseInt(this.subscription.subscriptionPaymentFrequencyUnits,10) === 1) {
          this.subscriptionPriceLabel = "Weekly";
        } else {
          this.subscriptionPriceLabel = "Every " + this.subscription.subscriptionPaymentFrequencyUnits + " Weeks";
        }
        break;
      case "Month(s)":
        if (parseInt(this.subscription.subscriptionPaymentFrequencyUnits, 10) === 1) {
          this.subscriptionPriceLabel = "Monthly";
        } else {
          this.subscriptionPriceLabel = "Every " + this.subscription.subscriptionPaymentFrequencyUnits + " Months";
        }
        break;
      case "Quarter(s)":
        if (parseInt(this.subscription.subscriptionPaymentFrequencyUnits, 10) === 1) {
          this.subscriptionPriceLabel = "Quarterly";
        } else {
          this.subscriptionPriceLabel = "Every " + this.subscription.subscriptionPaymentFrequencyUnits + " Quarters";
        }
        break;
      case "Year(s)":
        if (parseInt(this.subscription.subscriptionPaymentFrequencyUnits, 10) === 1) {
          this.subscriptionPriceLabel = "Yearly";
        } else {
          this.subscriptionPriceLabel = "Every " + this.subscription.subscriptionPaymentFrequencyUnits + " Years";
        }
        break;
      default:
        break;
    }
  }

  setSubscriptionDetailedPriceLabel() {
    let detailSubscriptionLabel = "";
    if (this.subscription.shippingFeeYN) {
      detailSubscriptionLabel += "Plus $" + this.subscription.shippingFee + " Shipping Fee";
    }
    this.subscriptionDetailedPriceLabel = detailSubscriptionLabel;
  }

  setTrialPriceLabel() {
    switch(this.subscription.trialDurationPeriod) {
      case "Hours(s)":
        if (parseInt(this.subscription.trialDurationUnits, 10) === 1) {
          this.trialPriceLabel = "Hourly";
        } else {
          this.trialPriceLabel = this.subscription.trialDurationUnits + " Hours";
        }
        break;
      case "Day(s)":
        if (parseInt(this.subscription.trialDurationUnits, 10) === 1) {
          this.trialPriceLabel = "Daily";
        } else {
          this.trialPriceLabel = this.subscription.trialDurationUnits + " Days";
        }
        break;
      case "Week(s)":
        if (parseInt(this.subscription.trialDurationUnits,10) === 1) {
          this.trialPriceLabel = "Weekly";
        } else {
          this.trialPriceLabel = this.subscription.trialDurationUnits + " Weeks";
        }
        break;
      case "Month(s)":
        if (parseInt(this.subscription.trialDurationUnits, 10) === 1) {
          this.trialPriceLabel = "Monthly";
        } else {
          this.trialPriceLabel = this.subscription.trialDurationUnits + " Months";
        }
        break;
      case "Quarter(s)":
        if (parseInt(this.subscription.trialDurationUnits, 10) === 1) {
          this.trialPriceLabel = "Quarterly";
        } else {
          this.trialPriceLabel = this.subscription.trialDurationUnits + " Quarters";
        }
        break;
      case "Year(s)":
        if (parseInt(this.subscription.trialDurationUnits, 10) === 1) {
          this.trialPriceLabel = "Yearly";
        } else {
          this.trialPriceLabel = this.subscription.trialDurationUnits + " Years";
        }
        break;
      default:
        if (parseInt(this.subscription.trialDurationUnits, 10) === 1) {
          this.trialPriceLabel = "Monthly";
        } else {
          this.trialPriceLabel = this.subscription.trialDurationUnits + " Months";
        }
        break;
    }
  }

  setMonthlyCostLabel() {
    if (this.subscription.subscriptionPaymentFrequency === "Month(s)" && this.subscription.subscriptionPaymentFrequencyUnits === 1) {
      this.monthlyCostLabel = "Actual";
    } else {
      this.monthlyCostLabel = "Estimated";
    }
  }

  setMonthlyDetailedCostLabel() {
    let monthlyDetailedCostLabel = "";
    if (this.subscription.shippingFeeYN) {
      monthlyDetailedCostLabel += "With Shipping";
    }
    this.monthlyDetailedCostLabel = monthlyDetailedCostLabel;
  }

  setFirstYearDetailedCostLabel() {
    let firstYearLabel = "";
    if (this.subscription.shippingFeeYN) {
       firstYearLabel += "With Shipping";
    }
    if (this.subscription.trialYN) {
      if (this.subscription.trialPrice > 0) {
        if (this.subscription.trialShippingFeeYN && this.subscription.trialShippingFee > 0) {
          if (firstYearLabel.length > 0) {
            firstYearLabel += " and $" + (this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2) + " Trial";
          } else {
            firstYearLabel += "With $" + (this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2) + " Trial";
          }
        } else { // No Trial Shipping Fee
          if (firstYearLabel.length > 0) {
            firstYearLabel += " and $" + this.subscription.trialPrice  + " Trial";
          } else {
            firstYearLabel += "With $" + this.subscription.trialPrice  + " Trial";
          }
        }
      } else { // Free Trial
        if (this.subscription.trialShippingFeeYN && this.subscription.trialShippingFee > 0) {
          if (firstYearLabel.length > 0) {
            firstYearLabel += " and $" + this.subscription.trialShippingFee + " Trial";
          } else {
            firstYearLabel += "With $" + this.subscription.trialShippingFee + " Trial";
          }
        } else { // No Trial Shipping Fee
          if (firstYearLabel.length > 0) {
            firstYearLabel += " and Free Trial";
          } else {
            firstYearLabel += "With Free Trial";
          }
        }
      }
    }
    this.firstYearDetailedCostLabel = firstYearLabel;

  }
  setFirstYearCostLabel() {
    this.firstYearCostLabel = "Estimated";
  }

  setAnnualCostLabel() {
    if (this.subscription.subscriptionPaymentFrequency === "Year(s)") {
      this.annualCostLabel = "Actual";
    } else {
      this.annualCostLabel = "Estimated";
    }
  }

  setAnnualDetailedCostLabel() {
    let annualDetailedCostLabel = "";
    if (this.subscription.shippingFeeYN) {
      annualDetailedCostLabel += "With Shipping";
    }
    this.annualDetailedCostLabel = annualDetailedCostLabel;
  }

  calculateCosts() {
    if (this.subscription.subscriptionPrice !== undefined && this.subscription.subscriptionPaymentFrequency !== undefined &&
        this.subscription.subscriptionPaymentFrequencyUnits !== undefined) {

      if (this.subscription.shippingFeesYN && this.subscription.shippingFees > 0) {
        this.totalCostsNormalized = ((this.subscription.shippingFees + this.subscription.subscriptionPrice) / this.subscription.subscriptionPaymentFrequencyUnits).toFixed(2);
      } else {
        this.totalCostsNormalized = (this.subscription.subscriptionPrice / this.subscription.subscriptionPaymentFrequencyUnits).toFixed(2);
      }

      switch(this.subscription.subscriptionPaymentFrequency) {
        case "Hour(s)":
          this.hourlyCost = this.totalCostsNormalized;
          this.dailyCost = (this.totalCostsNormalized * 24).toFixed(2);
          this.weeklyCost = (this.totalCostsNormalized * 24 * 7).toFixed(2);
          this.monthlyCost = ((this.totalCostsNormalized * 24 * 365) / 12).toFixed(2);
          this.quarterlyCost = (((this.totalCostsNormalized * 24 * 365) / 12) * 3).toFixed(2);
          this.yearlyCost = (this.totalCostsNormalized * 24 * 365).toFixed(2);
          break;
        case "Day(s)":
          this.hourlyCost = (this.totalCostsNormalized / 24).toFixed(2);
          this.dailyCost = this.totalCostsNormalized;
          this.weeklyCost = (this.totalCostsNormalized * 7).toFixed(2);
          this.monthlyCost = ((this.totalCostsNormalized * 365) / 12).toFixed(2);
          this.quarterlyCost = (((this.totalCostsNormalized * 365) / 12) * 3).toFixed(2);
          this.yearlyCost = (this.totalCostsNormalized * 365).toFixed(2);
          break;
        case "Week(s)":
          this.hourlyCost = ((this.totalCostsNormalized / 7) / 24).toFixed(2);
          this.dailyCost = (this.totalCostsNormalized / 7).toFixed(2);
          this.weeklyCost = this.totalCostsNormalized;
          this.monthlyCost = ((this.totalCostsNormalized * 52) / 12).toFixed(2);
          this.quarterlyCost = (((this.totalCostsNormalized * 52) / 12) * 3).toFixed(2);
          this.yearlyCost = (this.totalCostsNormalized * 52).toFixed(2);
          break;
        case "Month(s)":
          this.hourlyCost = ((this.totalCostsNormalized * 12) / 365 / 24).toFixed(2);
          this.dailyCost = ((this.totalCostsNormalized * 12) / 365).toFixed(2);
          this.weeklyCost = ((this.totalCostsNormalized * 12) / 52).toFixed(2);
          this.monthlyCost = this.totalCostsNormalized;
          this.quarterlyCost = (this.totalCostsNormalized * 3).toFixed(2);
          this.yearlyCost = (this.totalCostsNormalized * 12).toFixed(2);
          break;
        case "Quarter(s)":
          this.hourlyCost = ((this.totalCostsNormalized * 4) / 365 / 24).toFixed(2);
          this.dailyCost = ((this.totalCostsNormalized * 4) / 365).toFixed(2);
          this.weeklyCost = ((this.totalCostsNormalized * 4) / 52).toFixed(2);
          this.monthlyCost = (this.totalCostsNormalized / 3).toFixed(2);
          this.quarterlyCost = this.totalCostsNormalized;
          this.yearlyCost = (this.totalCostsNormalized* 4).toFixed(2);
          break;
        case "Year(s)":
          this.hourlyCost = ((this.totalCostsNormalized / 365) / 24).toFixed(2);
          this.dailyCost = (this.totalCostsNormalized / 365).toFixed(2);
          this.weeklyCost = (this.totalCostsNormalized / 52).toFixed(2);
          this.monthlyCost = (this.totalCostsNormalized / 12).toFixed(2);
          this.quarterlyCost = (this.totalCostsNormalized / 4).toFixed(2);
          this.yearlyCost = this.totalCostsNormalized;
          break;
        default:
          this.hourlyCost = ((this.totalCostsNormalized * 12) / 365 / 24).toFixed(2);
          this.dailyCost = ((this.totalCostsNormalized * 12) / 365).toFixed(2);
          this.weeklyCost = ((this.totalCostsNormalized * 12) / 52).toFixed(2);
          this.monthlyCost = this.totalCostsNormalized;
          this.quarterlyCost = (this.totalCostsNormalized * 3).toFixed(2);
          this.yearlyCost = (this.totalCostsNormalized * 12).toFixed(2);
          break;
      }
    }
  }
  calculateFirstYearCosts() {
    if (this.subscription.trialYN) {
      if (this.subscription.trialPrice === 0) {
        if (this.subscription.trialShippingFeeYN && this.subscription.trialShippingFee !== undefined) {
          switch(this.subscription.trialDurationPeriod) {
            case "Hour(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.hourlyCost) + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Day(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.dailyCost) + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Week(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.weeklyCost) + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Month(s)":
              this.firstYearCost = ((this.yearlyCost - (this.subscription.trialDurationUnits * this.monthlyCost) + this.subscription.trialShippingFee)).toFixed(2);
              break;
            case "Quarter(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.quarterlyCost) + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Year(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.yearlyCost) + this.subscription.trialShippingFee).toFixed(2);
              break;
            default:
              this.firstYearCost = ((this.yearlyCost - (this.subscription.trialDurationUnits * this.monthlyCost) + this.subscription.trialShippingFee)).toFixed(2);
              break;
          }
        } else { // No Trial Shipping Fees

          switch(this.subscription.trialDurationPeriod) {
            case "Hour(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.hourlyCost)).toFixed(2);
              break;
            case "Day(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.dailyCost)).toFixed(2);
              break;
            case "Week(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.weeklyCost)).toFixed(2);
              break;
            case "Month(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.monthlyCost)).toFixed(2);
              break;
            case "Quarter(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.quarterlyCost)).toFixed(2);
              break;
            case "Year(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.yearlyCost)).toFixed(2);
              break;
            default:
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.monthlyCost)).toFixed(2);
              break;
          }
        }
      } else { // Trial has a fee
        if (this.subscription.trialShippingFeeYN) {
          switch(this.subscription.trialDurationPeriod) {
            case "Hour(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.hourlyCost) + this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Day(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.dailyCost) + this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Week(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.weeklyCost) + this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Month(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.monthlyCost) + this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Quarter(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.quarterlyCost) + this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2);
              break;
            case "Year(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.yearlyCost) + this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2);
              break;
            default:
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.monthlyCost) + this.subscription.trialPrice + this.subscription.trialShippingFee).toFixed(2);
              break;
          }
        } else { // No trial shipping fees
          switch(this.subscription.trialDurationPeriod) {
            case "Hour(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.hourlyCost) + this.subscription.trialPrice).toFixed(2);
              break;
            case "Day(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.dailyCost) + this.subscription.trialPrice).toFixed(2);
              break;
            case "Week(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.weeklyCost) + this.subscription.trialPrice).toFixed(2);
              break;
            case "Month(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.monthlyCost) + this.subscription.trialPrice).toFixed(2);
              break;
            case "Quarter(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.quarterlyCost) + this.subscription.trialPrice).toFixed(2);
              break;
            case "Year(s)":
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.yearlyCost) + this.subscription.trialPrice).toFixed(2);
              break;
            default:
              this.firstYearCost = (this.yearlyCost - (this.subscription.trialDurationUnits * this.monthlyCost) + this.subscription.trialPrice).toFixed(2);
              break;
          }
        }
      }
    } else { // No Trial
      this.firstYearCost = this.yearlyCost;
    }
  }
}

export default SubscriptionCostCalc;