import React, { Component } from 'react';
import SubscriptionModalRow from './SubscriptionModalRow';

class SubscriptionModalList extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    let subscriptionComponents = [];
    if (this.props.subscriptions.length > 0) {
      let index = 0;
      for (let row in this.props.subscriptions) {
        let subscription = this.props.subscriptions[row];
        subscriptionComponents.push(
          <SubscriptionModalRow
            key={'subscription-' + subscription.id}
            id={subscription.id}
            index={index}
            subscription={subscription}
            addSubscriptionToCalc={this.props.addSubscriptionToCalc}
          />
        );
        index++;
      }

    } else {
      subscriptionComponents.push(
        <div className="row vendor-row-odd py-3 px-1">
          <div className="col-md-12">
            <div className="category-name">
              No subscriptions match your search criteria.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="scrollable px-1 col-md-12">
        <form className="px-1">
          {subscriptionComponents}
        </form>
      </div>
    );
  }
}
export default SubscriptionModalList;
