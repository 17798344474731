import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

class ShareLinkModal extends Component {

  constructor(props) {
    super(props);
    this.state = { copySuccess: 'COPY' };
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.handleCloseShareModal = this.handleCloseShareModal.bind(this);
    this.change = this.change.bind(this);
  }

  handleCloseShareModal() {
    this.props.showShareModal(false);
  }

  copyToClipboard = (e) => {
      this.input.select();
      document.execCommand('copy');
      e.target.focus();
      this.setState({ copySuccess: 'COPIED!' });
  };

  change() {
    console.log("change");
  }

  render() {
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCloseShareModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Your Calculator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Use the following link to share your calculator with others:
          <form>
            <div className="container">
              <div className="row py-3 my-3 share-link-box">
                <div className="col-md-9 ">
                  <input
                    className="share-link-textbox"
                    ref={(input) => this.input = input}
                    onChange={this.change}
                    value={this.props.shareURL}
                  />
                </div>
                { document.queryCommandSupported('copy') &&
                  <div className="col-md-3" onClick={this.copyToClipboard}>
                      <span className="copy-link blue">
                        {this.state.copySuccess}
                      </span>
                  </div>
                }
                { document.queryCommandSupported('copy') && iOS &&
                <div className="col-3" onClick={this.iosCopyToClipboard}>
                              <span className="copy-link blue ">
                                {this.state.copySuccess} {iOS}
                              </span>
                </div>
                }
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ShareLinkModal;
