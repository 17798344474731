import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import * as RestApi from "../modules/RestApi";

class SharePage extends Component {

  constructor(props) {
    super(props);
    this.state = { copySuccess: 'COPY' };
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.iosCopyToClipboard = this.iosCopyToClipboard.bind(this);
    this.change = this.change.bind(this);
  }

  copyToClipboard = (e) => {
    this.input.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ copySuccess: 'COPIED!' });
  };

  change() {
    console.log("change");
  }

  iosCopyToClipboard = (el) => {
    let oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    let s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand('copy');

    this.setState({ copySuccess: 'COPIED!' });
  };


  render() {

    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    console.log("iOS=" + iOS);
    return (
      <div className="share-page container-fluid">
        <div className="row">
          <div className="col-12 text-center my-2">

            <div className="mx-0 py-3 p-0 gray-bottom-border">
              <div>
                Use the following link to share<br/>your calculator with others:
                <form>
                  <div className="container">
                    <div className="row py-3 my-3 share-link-box">
                      <div className="col-9">
                        <textarea
                          className="share-link-textbox"
                          cols={25}
                          rows={2}
                          ref={(input) => this.input = input}
                          onChange={this.change}
                          value={RestApi.CALCULATOR_BASE_URL + RestApi.PATH_CALCULATOR + '/' + this.props.calculatorKey}
                        />
                      </div>
                      { document.queryCommandSupported('copy') && !iOS &&
                      <div className="col-3" onClick={this.copyToClipboard}>
                              <span className="copy-link blue">
                                {this.state.copySuccess}
                              </span>
                      </div>
                      }
                    </div>
                    <div className="row pb-3">
                      <div className="col-12">
                        <Link to={'/calculator/' + this.props.calculatorKey}><button className="btn btn-primary">Done</button></Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

}
export default withRouter(SharePage)