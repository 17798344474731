import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";

class SubscriptionsMobileHeader extends Component {
  render() {
    return (
        <div className="row light-gray-background px-2 subscriptions header">
          <div className="col-8 py-2 pr-0">
            <input
              onChange={this.props.subscriptionFilterChange}
              type="search"
              className="form-control"
              id="subscriptionSearch"
              placeholder="Search"
            />
          </div>
          <div className="col-4 py-2 pl-0 line-height-1">
            <span className="primary-link"><small><Link to={`/add_subscription`}>Subscription not found? Add It.</Link></small></span>
          </div>
        </div>
      );
    }
  }

export default withRouter(SubscriptionsMobileHeader);
