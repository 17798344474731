import React, {Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

class CalculatorMobileToolbar extends Component {

  render() {
    return (
      <div className="row toolbar px-0 pt-1">
        <div className="col-7 text-left">
          <Link to={"/subscriptions"}>
            <button
              id="btnAddSubscription"
              className="btn btn-primary btn-sm d-print-none"
              type="button">
                <FontAwesomeIcon icon="plus-circle"/> Add
            </button>
          </Link>
          <button
            onClick={this.props.createNewCalculator}
            id="btnNewCalculator"
            className="btn btn-secondary btn-sm ml-3 d-print-none"
            type="button">
              <FontAwesomeIcon icon="calculator"/> New
          </button>
        </div>
        <div className="col-5 text-right">
          <Link to={"/share"}>
            <button
              className="btn btn-secondary btn-sm d-print-none"
              type="button">
                <FontAwesomeIcon icon="share"/> Share
            </button>
          </Link>
        </div>
      </div>
    )
  }
}
export default CalculatorMobileToolbar