import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';

class CalculatorMobileRow extends Component {

  constructor(props) {
    super(props);
    this.removeSubscriptionFromCalc = this.removeSubscriptionFromCalc.bind(this);
    this.goToSubscriptionDetail = this.goToSubscriptionDetail.bind(this);
  }

  removeSubscriptionFromCalc() {
      this.props.removeSubscriptionFromCalc(this.props.subscription);
  }

  goToSubscriptionDetail() {
    const location = {
      pathname: '/subscription/' + this.props.subscription.id,
      state: {subscription: this.props.subscription}
    };
    this.props.history.push(location);
  }

  render() {
    let totalPrice = 0;
    let totalPriceLabel = "";
    switch(this.props.showCost) {
      case "Published":
        totalPrice = this.props.subscription.subscriptionPrice;
        totalPriceLabel = this.props.subscription.subscriptionPriceLabel;
        break;
      case "Monthly":
        totalPrice = this.props.subscription.monthlyCost;
        totalPriceLabel = this.props.subscription.monthlyCostLabel;
        break;
      case "First Year":
        totalPrice = this.props.subscription.firstYearCost;
        totalPriceLabel = this.props.subscription.firstYearCostLabel;
        break;
      case "Annual":
        totalPrice = this.props.subscription.annualCost;
        totalPriceLabel = this.props.subscription.annualCostLabel;
        break;
      default:
        totalPrice = this.props.subscription.subscriptionTotalCosts;
        totalPriceLabel = this.props.subscription.subscriptionPriceLabel;
        break;

    }
    if (this.props.subscription != null) {
      return (
        <div className="row py-2 gray-bottom-border calcrow">
          <div onClick={this.goToSubscriptionDetail}  className="col-7 text-left">
            <div className="table-row-label">{this.props.subscription.companyName}</div>
            <div className="small-text-muted pt-0 mt-0">{this.props.subscription.subscriptionName}</div>
            <div className="small-text-muted pt-0 mt-0">{this.props.subscription.subscriptionPriceLabel}
              { this.props.subscription.trialYN &&
              <span> - <FontAwesomeIcon icon="gift" /></span>
              }
            </div>
          </div>
          <div onClick={this.goToSubscriptionDetail} className="col-4 text-right">
            <div className="table-row-label">
              <NumberFormat
                value={totalPrice}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
            </div>
            <div className="small-text-muted pt-0 mt-0">{totalPriceLabel}</div>
            <div className="small-text-muted pt-0 mt-0" >
              { this.props.subscription.shippingFeeYN && this.props.showCost === "Published" &&
                <span>Plus <FontAwesomeIcon icon="truck" />&nbsp;Fee</span>
              }
              { this.props.subscription.shippingFeeYN && this.props.showCost !== "Published" &&
                <span>With <FontAwesomeIcon icon="truck" />&nbsp;Fee</span>
              }
              { this.props.subscription.shippingFeeYN && this.props.subscription.trialYN && this.props.showCost === "First Year" &&
                <span> & Trial</span>
              }
              { !this.props.subscription.shippingFeeYN && this.props.subscription.trialYN && this.props.showCost === "First Year" &&
              <span> With Trial</span>
              }
            </div>
          </div>
          <div className="col-1 d-print-none text-center pl-0"  >
            <div className="secondary-link-text mb-2" onClick={this.goToSubscriptionDetail}>
              <strong><FontAwesomeIcon icon="chevron-right" /></strong>
            </div>
            <div className="secondary-link-text" onClick={this.removeSubscriptionFromCalc}>
              <FontAwesomeIcon icon="trash" />
            </div>
          </div>
        </div>
      );
    } else {
      return ("");
    }

  }
}

export default withRouter(CalculatorMobileRow);
