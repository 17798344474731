import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Collapse } from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SubscriptionMobileRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showText: false
    };

    this.addSubscriptionToCalc = this.addSubscriptionToCalc.bind(this);
    this.showDescription = this.showDescription.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  addSubscriptionToCalc() {
    this.props.addSubscriptionToCalc(this.props.subscription);
  }

  showDescription() {
    console.log("here");
    this.setState({
      showText: !this.state.showText
    })
  }
  render() {
    const maxLimit = 75;
    if (this.props.subscription != null && this.props.subscription.publishYN) {
      return (
        <div className="row py-3 gray-bottom-border" key={"row_" + (this.props.subscription.id)}>
          <div className="col-8 text-left">

            <div className="row">
              <div className="subscription-name col-10 text-left">
                {this.props.subscription.companyName}
              </div>
              <div className="text-right small-text-muted col-2">
                {this.props.subscription.subscriptionURL !== "" &&
                <a
                  href={this.props.subscription.subscriptionURL}
                  target="window"
                  className="d-print-none"
                >
                  Visit
                </a>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 subscription-name">
                {this.props.subscription.subscriptionName} - {this.props.subscription.subscriptionPriceLabel}
                { this.props.subscription.trialYN &&
                <span> - <FontAwesomeIcon icon="gift" /></span>
                }
              </div>
            </div>
            <div>
              {
                (this.props.subscription.subscriptionDescription).length < maxLimit ?
                  <div className="subscription-description">
                    {this.props.subscription.subscriptionDescription}
                  </div>
                  : (
                  <div className="subscription-description">
                    { !this.state.showText &&
                    <div>
                      {  (this.props.subscription.subscriptionDescription).substring(0, maxLimit-3) + "... " }
                      <br/><div className="primary-link" onClick={this.showDescription}>Show more</div>
                    </div>
                    }
                    <Collapse isOpen={this.state.showText}>
                      <span>
                        { this.props.subscription.subscriptionDescription } &nbsp;<span className="primary-link" onClick={this.showDescription}>Show less</span>
                      </span>
                    </Collapse>
                  </div>
                )
              }
            </div>
          </div>
          <div className="col-3 text-right">

            <div className="row subscription-price">
              <div className="col-12">
                <NumberFormat
                  value={this.props.subscription.subscriptionPrice}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </div>
            </div>
            <div className="row line-height-1">
              <div className="col-12">
                <span className="small-text-muted">{this.props.subscription.subscriptionPriceLabel}</span>
              </div>
            </div>
            <div className="row line-height-1">
              <div className="col-12">
                <div className="small-text-muted pt-0 mt-1" >
                  { this.props.subscription.shippingFeeYN &&
                  <span>+ <FontAwesomeIcon icon="truck" /> Fee&nbsp;</span>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-1 text-right">
            <input onClick={this.addSubscriptionToCalc} type="checkbox" className="form-check-input" id="exampleCheck1" />
          </div>
        </div>
      );
    } else {
      return ("");
    }
  }
}

export default SubscriptionMobileRow;
