import React, { Component } from "react";
import axios from 'axios';
import * as RestApi from '../modules/RestApi';
import SubscriptionsMobileToolbar from '../components/Subscriptions/SubscriptionsMobileToolbar';
import SubscriptionsMobileHeader from '../components/Subscriptions/SubscriptionsMobileHeader';
import SubscriptionsMobileList from '../components/Subscriptions/SubscriptionsMobileList';
import {CSSTransition} from "react-transition-group";

class SubscriptionsMobilePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterTerm: "",
      subscriptions: [],
      lastSubscriptions: [],
      loading: false,
    };
    this.subscriptionFilterChange = this.subscriptionFilterChange.bind(this);
  }

  componentDidMount() {
    this.setState({calcRowCount: this.props.footerCount});
    this.getSubscriptions();
  }

  getSubscriptions() {
    const requestSubscriptionsUrl = RestApi.API_BASE + RestApi.PATH_SUBSCRIPTIONS;
    console.log('Fetching Subscriptions...');
    this.setState({loading: true});

    axios(requestSubscriptionsUrl, {
      withCredentials: true,
    })
      .then((result) => {
        if (result.data != null) {
          let sortedSubscriptions = result.data.subscriptions.sort((a,b) => {
            if (a.companyName < b.companyName) return -1;
            if (a.companyName > b.companyName) return 1;
            if (a.subscriptionName < b.subscriptionName) return -1;
            if (a.subscriptionName > b.subscriptionName) return 1;
            if (a.subscriptionPaymentFrequencyUnits < b.subscriptionPaymentFrequencyUnits) return -1;
            if (a.subscriptionPaymentFrequencyUnits > b.subscriptionPaymentFrequencyUnits) return 1;
            return 0;
          });
          this.setState({
            subscriptions: sortedSubscriptions,
            lastSubscriptions: sortedSubscriptions,
            loading: false
          });

        } else {
          this.setState({ subscriptions: null });
        }
      })
      .then(() => {
        console.log(`${this.state.subscriptions.length} Subscriptions Loaded`);
        this.props.setFooterCount(this.state.subscriptions.length);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
        if (error.response || !error.status) {
          this.setState({
            serverIssue: true,
            serverError: "Server error, please try again."
          });
        }
      });
  }

  filterSubscriptions() {
    if (this.state.filterTerm.length > 1) {
      const words = this.state.filterTerm.split(" ");
      let filteredSubscriptions = this.state.subscriptions.filter(
        sub => {
          let rt = false;
          sub.score = 0;
          words.forEach (word => {
            if (word.length > 1) {
              console.log("word =" + word);
              if (sub.companyName.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
                sub.score += 1;
                rt=true;
              }
              if (sub.subscriptionName.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
                sub.score += 1;
                rt=true;
              }
            }
          } );
          return rt;
        }
      );
      filteredSubscriptions.sort((a,b) => {
        if (a.score < b.score) return 1;
        if (a.score > b.score) return -1;
        return 0;
      });

      this.setState({
        subscriptions: filteredSubscriptions
      }, () => {
        this.props.setFooterCount(this.state.subscriptions.length);
      });

    } else {
      this.setState({
        subscriptions: this.state.lastSubscriptions
      }, () => {
        this.props.setFooterCount(this.state.subscriptions.length);
      });

    }
  }

  subscriptionFilterChange(e) {
    this.setState({
      filterTerm: e.target.value
    }, () => {
      this.filterSubscriptions();
    });
  }

  render() {
    if (this.props.isMobileDevice) {
      return (
        <CSSTransition
          key={"detailFade"}
          in={!this.state.showSubscriptionView}
          timeout={500}
          classNames="calc"
        >
          <div className="container-fluid subscription-container">
            <div className="row status">
              <div className="col-12 px-0 text-center my-2">
                <SubscriptionsMobileToolbar
                  {...this.props}
                  calcRowCount={this.props.calcRowCount}
                  setFooterCount={this.props.setFooterCount}
                />
                <SubscriptionsMobileHeader
                  filterSubscriptions={this.filterSubscriptions}
                  subscriptionFilterChange={this.subscriptionFilterChange}
                  showSubscriptionMobileForm={this.showSubscriptionMobileForm}
                />
                <SubscriptionsMobileList
                  subscriptions={this.state.subscriptions}
                  addSubscriptionToCalc={this.props.addSubscriptionToCalc}
                  removeSubscriptionFromCalc={this.props.removeSubscriptionFromCalc}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </div>
        </CSSTransition>
      )
    } else {
      return ('');
    }
  }
}
export default SubscriptionsMobilePage;
