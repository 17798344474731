import React, {Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CalculatorToolbar extends Component {

  constructor(props) {
    super(props);
    this.showSubscriptionModal = this.showSubscriptionModal.bind(this);
    this.showShareModal = this.showShareModal.bind(this);
  }

  showSubscriptionModal() {
    this.props.showSubscriptionModal(true);
  }

  showShareModal() {
    this.props.showShareModal(true);
  }

  render() {
    return (

      <div className="row pb-1">
        <div className="col-md-6 mb-1 text-left">
          <button id="btnAddSubscription" onClick={this.showSubscriptionModal} className="btn btn-primary btn-sm d-print-none" type="button">
            <FontAwesomeIcon icon="plus-circle" /> Add Subscription
          </button>
          <button onClick={this.props.createNewCalculator} id="btnNewCalculator" className="btn btn-secondary btn-sm ml-3 d-print-none" type="button">
            <FontAwesomeIcon icon="calculator" /> New Calculator
          </button>
        </div>
        <div className="col-md-6 text-right">
          <button onClick={this.showShareModal} className="btn btn-secondary btn-sm d-print-none" type="button">
            <FontAwesomeIcon icon="share" /> Share
          </button>
        </div>
      </div>
    )
  }
}
export default CalculatorToolbar