import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import {Collapse} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SubscriptionModalRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showText: false
    };
    this.handleSubscriptionCheck = this.handleSubscriptionCheck.bind(this);
    this.showDescription = this.showDescription.bind(this);
  }
  handleSubscriptionCheck() {
    this.props.addSubscriptionToCalc(this.props.subscription);
  }
  showDescription() {
    this.setState({
      showText: !this.state.showText
    })
  }
  render() {
    const maxLimit = 160;
    if (this.props.subscription != null && this.props.subscription.publishYN) {
      return (
        <div className="row vendor-row-odd py-1 px-1" key={"row_" + (this.props.subscription.id)}>
          <div className="col-md-8">
            <div className="row">
              <div className="subscription-name col-10 text-left">
                {this.props.subscription.companyName}
              </div>
              <div className="text-right small-text-muted col-2">
                {this.props.subscription.subscriptionURL !== "" &&
                <a
                  href={this.props.subscription.subscriptionURL}
                  target="window"
                  className="d-print-none"
                >
                  Visit <FontAwesomeIcon icon="external-link-alt" />
                </a>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 subscription-name">
                {this.props.subscription.subscriptionName} - {this.props.subscription.subscriptionPriceLabel}
              </div>
            </div>
            <div>
              {
                (this.props.subscription.subscriptionDescription).length < maxLimit ?
                  <div className="subscription-description">
                    {this.props.subscription.subscriptionDescription}
                  </div>
                  : (
                    <div className="subscription-description">
                      { !this.state.showText &&
                      <div>
                        {  (this.props.subscription.subscriptionDescription).substring(0, maxLimit-3) + "... " }
                        <br/><div className="primary-link" href="#" onClick={this.showDescription}>Show more</div>
                      </div>
                      }
                      <Collapse isOpen={this.state.showText}>
                      <span>
                        { this.props.subscription.subscriptionDescription } &nbsp;<div className="primary-link" onClick={this.showDescription}>Show less</div>
                      </span>
                      </Collapse>
                    </div>
                  )
              }
            </div>
          </div>
          <div className="offset-1 col-md-2">
            <div className="row subscription-price">
              <div className="col-12">
                <NumberFormat
                  value={this.props.subscription.subscriptionPrice}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </div>
            </div>
            <div className="row line-height-1">
              <div className="col-12">
                <span className="small-text-muted">{this.props.subscription.subscriptionPriceLabel}</span>
              </div>
            </div>
            <div className="row line-height-1">
              <div className="col-12">
                <div className="small-text-muted pt-0 mt-1" >
                  { this.props.subscription.shippingFeeYN &&
                  <span><FontAwesomeIcon icon="truck" />&nbsp;</span>
                  }
                  { this.props.subscription.trialYN &&
                  <FontAwesomeIcon icon="gift" />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1 text-left form-group form-check">
            <input onClick={this.handleSubscriptionCheck} type="checkbox" className="form-check-input" id="exampleCheck1" />
          </div>
        </div>
      );
    } else {
      return ("");
    }
  }
}

export default SubscriptionModalRow;
