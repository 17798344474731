import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import SubscriptionModalList from './SubscriptionModalList';
import SubscriptionModalForm from './SubscriptionModalForm';
import * as RestApi from "../../modules/RestApi";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SubscriptionModal extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      filterTerm: "",
      subscriptions: [],
      cachedSubscriptions: [],
      showSubscriptionForm: false,
      footerCount:0
    };
    this.filterChange = this.filterChange.bind(this);
    this.hideSubscriptionModal = this.hideSubscriptionModal.bind(this);
    this.showSubscriptionForm = this.showSubscriptionForm.bind(this);
    this.hideSubscriptionForm = this.hideSubscriptionForm.bind(this);
    this.setFooterCount = this.setFooterCount.bind(this);
  }
  componentDidMount() {
    this._mounted = true;
    this.getSubscriptions();
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  setFooterCount(amount) {
    this.setState({footerCount: amount});
  }
  showSubscriptionForm() {
    this.setState({
      showSubscriptionForm: true
    })
  }
  hideSubscriptionForm() {
    this.setState({
      showSubscriptionForm: false
    })
  }
  hideSubscriptionModal() {
    this.props.showSubscriptionModal(false);
  }

  getSubscriptions() {
    const requestSubscriptionsUrl = RestApi.API_BASE + RestApi.PATH_SUBSCRIPTIONS;
    console.log('Fetching Subscriptions...');

    axios(requestSubscriptionsUrl, {
      withCredentials: true,
    })
      .then((result) => {
        if (result.data != null) {
          let sortedSubscriptions = result.data.subscriptions.sort((a,b) => {
            if (a.companyName < b.companyName) return -1;
            if (a.companyName > b.companyName) return 1;
            if (a.subscriptionName < b.subscriptionName) return -1;
            if (a.subscriptionName > b.subscriptionName) return 1;
            if (a.subscriptionPaymentFrequencyUnits < b.subscriptionPaymentFrequencyUnits) return -1;
            if (a.subscriptionPaymentFrequencyUnits > b.subscriptionPaymentFrequencyUnits) return 1;
            return 0;
          });
          if (this._mounted) {
            this.setState({
              subscriptions: sortedSubscriptions,
              lastSubscriptions: sortedSubscriptions
            }, () => {
              this.setFooterCount(this.state.subscriptions.length);
            });
          }
        } else {
          if (this._mounted) {
            this.setState({
              subscriptions: null
            });
          }
        }
      })
      .then(() => {
        console.log(`${this.state.subscriptions.length} Subscriptions Loaded`);

      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
        if (error.response || !error.status) {
          this.setState({
            serverIssue: true,
            serverError: "Server error, please try again."
          });
        }
      });
  }

  filterSubscriptions() {
    this.setState({
      subscriptions: this.state.lastSubscriptions
    }, ()=> {
      if (this.state.filterTerm.length > 1) {
        const words = this.state.filterTerm.split(" ");
        let filteredSubscriptions = this.state.subscriptions.filter(
          sub => {
            let rt = false;
            sub.score = 0;
            words.forEach (word => {
              if (word.length > 1) {
                if (sub.companyName.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
                  sub.score += 1;
                  rt=true;
                }
                if (sub.subscriptionName.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
                  sub.score += 1;
                  rt=true;
                }
              }
            } );
            return rt;
          }
        );
        filteredSubscriptions.sort((a,b) => {
          if (a.score < b.score) return 1;
          if (a.score > b.score) return -1;
          return 0;
        });
        this.setState({
          subscriptions: filteredSubscriptions
        }, () => {
          this.setFooterCount(this.state.subscriptions.length);
        })
      } else {
        this.setState({
          subscriptions: this.state.lastSubscriptions
        }, () => {
          this.setFooterCount(this.state.subscriptions.length);
        });

      }
    });
  }

  filterChange(e) {
    console.log("e.value=" + e.target.value);
    this.setState({
      filterTerm: e.target.value
    }, () => {
      this.filterSubscriptions();
    });

  }

  clearFilter() {
    this.setState({
      subscriptions: this.state.lastSubscriptions
    }, () => {
      this.setFooterCount(this.state.subscriptions.length);
    })
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleCloseNewSubscriptionModal}
        dialogClassName="wide-modal"
      >
        <Modal.Header className="navbar-background white">
          <Modal.Title className="white">Add Subscription(s) to the Calculator</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 py-0">

          {!this.state.showSubscriptionForm &&
            <div className="container-fluid pt-0 pb-0 px-0">
              <div className="row mx-0 mb-1 py-1 search-header light-gray-background">
                <div className="col-md-6 pt-1 table-row-label">Select from the subscriptions below and then click Done.</div>
                <div className="col-md-6 pt-1">
                  <input onChange={this.filterChange} type="search" className="form-control" id="subscriptionSearch" aria-describedby="emailHelp" placeholder="Search" />
                </div>
              </div>
              <div className="row mx-0 mb-0">
              <SubscriptionModalList
                subscriptions={this.state.subscriptions}
                addSubscriptionToCalc={this.props.addSubscriptionToCalc}
              />
              </div>
            </div>
          }
          {this.state.showSubscriptionForm &&
            <SubscriptionModalForm
              addSubscriptionToCalc={this.props.addSubscriptionToCalc}
              hideSubscriptionForm={this.hideSubscriptionForm}
            />
          }
        </Modal.Body>
        {!this.state.showSubscriptionForm &&
          <Modal.Footer className="light-gray-background py-2">
            <div className="container">
              <div className="row">
                <div className="col-md-2 pl-0 text-left">
                  <small>{this.state.footerCount} Item{this.state.footerCount > 0 ? "s" : ""}</small>
                </div>
                <div className="col-md-8 pl-0 table-row-label text-center">
                  <div className="row">
                    <div className="col-12">Cannot find a subscription in the list above? <span className="primary-link" onClick={this.showSubscriptionForm}>Add It</span></div>
                  </div>
                  <div className="row light-gray-background">
                    <div className="col-12 center dark-gray">
                      <small>Key: <FontAwesomeIcon icon="truck"/> = Plus Shipping Fee (not included), </small>&nbsp;&nbsp;
                      <small><FontAwesomeIcon icon="gift"/> = Trial Available</small>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 text-right">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={this.hideSubscriptionModal}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        }
      </Modal>
    )
  }
}

SubscriptionModal.propTypes = {
  //id: PropTypes.any.isRequired,
};

export default SubscriptionModal;
