export const SubscriptionReducer = (state = this.state, action = {}) => {
    switch (action.type) {
        case 'Add': {
            state.savedCalculator = [...state.savedCalculator, action.payload];
            return {
                ...state,
            };
        }

        case 'Update': {
            state.subscriptionsHash[action.subscriptionId] = {
                ...state.subscriptionsHash[action.subscriptionId],
                ...action.payload,
            };
            return {
                ...state,
            };
        }

        case 'Remove': {
            // return all the items not matching the action.id
            const prunedIds = state.subscriptionIds.filter(item => item !== action.subscriptionId);
            // delete the hash associated with the action.id
            delete state.subscriptionsHash[action.subscriptionId];

            return {
                subscriptionIds: prunedIds,
                subscriptionsHash: state.subscriptionsHash,
            };
        }

        default: {
            return state;
        }
    }
};

// attach a new subscription to push into the array
export const dispatchAddSubscription = subscription => ({
    type: 'Add',
    payload: subscription,
});

// get the subscription by id, and then patch in the data
export const dispatchEditSubscription = (id, data) => ({
    type: 'Update',
    subscriptionId: id,
    payload: data,
});

// // filter out the subscription matching the ID
// const dispatchRemoveSubscription = id => ({
//   type: 'Remove',
//   subscriptionId: id,
// });