import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import SubscriptionMobileDetailToolbar from "./SubscriptionMobileDetailToolbar";
import NumberFormat from 'react-number-format';

class SubscriptionDetailPage extends Component {

  render() {
    return (
      <div className="subscription-detail-page container-fluid">
        <div className="row">
          <div className="col-12 text-center my-2">
            <SubscriptionMobileDetailToolbar
              createNewCalculator={this.createNewCalculator}
              calculatorKey={this.props.calculatorKey}
              subscription={this.props.location.state.subscription}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-left mt-2">
            <div><strong>{this.props.location.state.subscription.companyName}</strong></div>
            <div>{this.props.location.state.subscription.subscriptionName}</div>
            <div className={"mt-3"}><strong>Category</strong></div>
            <div>{this.props.location.state.subscription.category1}</div>
            <div className={"mt-3"}><strong>Description</strong></div>
            <div className={"small-text-muted"}>{this.props.location.state.subscription.subscriptionDescription}</div>
            <div className={"mt-3"}><strong>Trial Available</strong></div>
            <div>{this.props.location.state.subscription.trialYN ? 'Yes' : 'No'}</div>
            <div className={"mt-3"}><strong>Trial Details</strong></div>
            <div>
              {this.props.location.state.subscription.trialPrice === 0 ? "Free " : "$" + this.props.location.state.subscription.trialPrice + " Trial for "}
              {this.props.location.state.subscription.trialDurationUnits + " " + this.props.location.state.subscription.trialDurationPeriod}

            </div>
            <div className={"mt-3"}><strong>Published Price</strong></div>
            <div>
              <NumberFormat
                value={this.props.location.state.subscription.subscriptionPrice}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
            </div>
            <div className={"mt-3"}><strong>Payment Due</strong></div>
            <div>
              {this.props.location.state.subscription.subscriptionPaymentFrequency=== 1 ? '' : "Every " + this.props.location.state.subscription.subscriptionPaymentFrequency + ' '}
              {this.props.location.state.subscription.subscriptionPriceLabel}
            </div>
            <div className={"mt-3"}><strong>Shipping Fees</strong></div>
            <div>
              <NumberFormat
                value={this.props.location.state.subscription.shippingFee === "" ? 0 : this.props.location.state.subscription.shippingFee}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
            </div>
            <div className={"mt-3"}><strong>Total Costs</strong></div>
            <div>
              <NumberFormat
                value={this.props.location.state.subscription.subscriptionTotalCosts === "" ? 0 : this.props.location.state.subscription.subscriptionTotalCosts}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center my-2">
            <SubscriptionMobileDetailToolbar
              createNewCalculator={this.createNewCalculator}
              calculatorKey={this.props.calculatorKey}
              subscription={this.props.location.state.subscription}
            />
          </div>
        </div>
      </div>
    )
  }

}
export default withRouter(SubscriptionDetailPage)