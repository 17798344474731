import React, {Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';

class CalculatorMobileFooter extends Component {

  render() {
    return (
      <div className="container">
        <div className="row light-gray-background">
          <div className="col-3 text-left py-1 dark-gray">
            <small>{this.props.footerCount} Item{this.props.footerCount > 1 ? "s" : ""}</small>
          </div>
          <div className="col-4 text-left py-1 pr-0 dark-gray">
            <small><FontAwesomeIcon icon="truck"/> = Shipping Fee</small>
          </div>
          <div className="col-5 text-right py-1 dark-gray">
            <small><FontAwesomeIcon icon="gift"/> = Trial Available</small>
          </div>
        </div>
        <div className="row navbar-background">
          <div className="col-8 text-left py-1">
            <div className="table-row-label white">
              {this.props.showCost !== "Published" &&
              <span>Total {this.props.showCost} Costs</span>
              }
              {this.props.showCost === "Published" &&
              <span>&nbsp;</span>
              }
            </div>
          </div>
          <div className="col-4 text-right py-1">
            <div className="table-row-label white">
              {this.props.showCost === "Annual" &&
              <NumberFormat
                value={this.props.annualCost}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
              }
              {this.props.showCost === "Monthly" &&
              <NumberFormat
                value={this.props.monthlyCost}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
              }
              {this.props.showCost === "First Year" &&
              <NumberFormat
                value={this.props.firstYearCost}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default CalculatorMobileFooter