import React, { Component } from "react";
import {Link, withRouter} from 'react-router-dom';
import CalculatorMobileFooter from './Calculator/CalculatorMobileFooter';

class MainFooter extends Component {

  render() {
    return (
      <div className="footer">
        <CalculatorMobileFooter
          monthlyCost={this.props.monthlyCost}
          firstYearCost={this.props.firstYearCost}
          annualCost={this.props.annualCost}
          showCost={this.props.showCost}
          footerCount={this.props.footerCount}

        />
        <div className="container-fluid">
          <div className="row navbar-background">
            <div className="col-8 text-left pb-1">
              <div><small><u><Link className="dark-medium-text light-gray" to={`${this.props.match.url}feedback`}>Feedback</Link></u></small></div>
            </div>
            <div className="col-4 text-right pb-1">
              <div><small><u><Link className="dark-medium-text light-gray" to={`${this.props.match.url}disclaimer`}>Disclaimer</Link></u></small></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MainFooter);
