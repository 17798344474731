import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faTrash, faPlusCircle, faShare,
  faExternalLinkAlt, faInfoCircle,
  faCalculator, faTruck, faGift, faChevronLeft, faChevronRight
} from '@fortawesome/free-solid-svg-icons'

import MainNavBar from "./components/MainNavBar";
import MainFooter from "./components/MainFooter";
import CalculatorPage from './pages/CalculatorPage';
import SubscriptionsMobilePage from './pages/SubscriptionsMobilePage';
import DisclaimerPage from './pages/DisclaimerPage';
import FeedbackPage from './pages/FeedbackPage';
import SharePage from './pages/SharePage';
import * as Reducer from "./components/Calculator/CalculatorReducers";
import SubscriptionDetailPage from "./components/Subscriptions/SubscriptionDetailPage";
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import SubscriptionMobileForm from "./components/Subscriptions/SubscriptionMobileForm";

const history = createHistory();

library.add(fab, faTrash, faPlusCircle, faShare, faExternalLinkAlt, faInfoCircle, faCalculator, faTruck, faGift, faChevronLeft, faChevronRight);

ReactGA.initialize('UA-83354216-1');

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.sendPageView(this.context.router.history.location);
    this.context.router.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render() {
    return this.props.children;
  }
}

class App extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      savedCalculator: [],
      monthlyCost: 0,
      firstYearCost: 0,
      annualCost: 0,
      showCost: "Annual",
      hasChanges: false,
      calculatorKey:"",
      showMainFooter: true,
      footerCount: 0,
      calcRowCount: 0
    };

    this.addSubscriptionToCalc = this.addSubscriptionToCalc.bind(this);
    this.removeSubscriptionFromCalc = this.removeSubscriptionFromCalc.bind(this);
    this.setSavedCalculator = this.setSavedCalculator.bind(this);
    this.setCalcTotals = this.setCalcTotals.bind(this);
    this.setShowCost = this.setShowCost.bind(this);
    this.setHasChanges = this.setHasChanges.bind(this);
    this.setCalculatorKey = this.setCalculatorKey.bind(this);
    this.updateCalcTotals = this.updateCalcTotals.bind(this);
    this.displayMainFooter = this.displayMainFooter.bind(this);
    this.setFooterCount = this.setFooterCount.bind(this);
    this.setCalcRowCount = this.setCalcRowCount.bind(this);

  }

  setCalcRowCount(numItems) {
    this.setState({
      calcRowCount: numItems
    })
  }

  setFooterCount(numItems) {
    this.setState({
      footerCount: numItems
    })
  }

  addSubscriptionToCalc(subscription) {
    console.log(`Adding Subscription to Calculator`);
    const state = Reducer.SubscriptionReducer(
      this.state,
      Reducer.dispatchAddSubscription(subscription),
    );
    this.setState({
      ...state,
      hasChanges: true,
    }, () => {
      this.updateCalcTotals();
      this.setState({calcRowCount: this.state.savedCalculator.length});
    });
  }

  removeSubscriptionFromCalc(subscription) {
    console.log("Removing Subscription From Calculator");
    const newCalculatorList = this.state.savedCalculator.filter(sub => sub.id !== subscription.id);
    this.setState({
      savedCalculator: newCalculatorList,
      hasChanges: true
    }, ()=> {
      this.updateCalcTotals();
    });

  };

  updateCalcTotals() {
    let monthlyCost = 0;
    let firstYearCost = 0;
    let annualCost = 0;
    for (let row in this.state.savedCalculator) {
      let subscription = this.state.savedCalculator[row];
      if (subscription.monthlyCost !== "") {
        monthlyCost += parseFloat(subscription.monthlyCost);
      }
      if (subscription.firstYearCost !== "") {
        firstYearCost += parseFloat(subscription.firstYearCost);
      }
      if (subscription.annualCost !== "") {
        annualCost += parseFloat(subscription.annualCost);
      }
    }
    this.setCalcTotals({
      monthlyCost: monthlyCost,
      firstYearCost: firstYearCost,
      annualCost: annualCost
    })
  }

  displayMainFooter(show) {
    this.setState({
      showMainFooter: show
    });
  }

  setCalcTotals(totals) {
    this.setState({
      monthlyCost: totals.monthlyCost,
      firstYearCost: totals.firstYearCost,
      annualCost: totals.annualCost,
    })
  }

  setCalculatorKey(calculatorKey) {
    this.setState({
      calculatorKey: calculatorKey,
    });
  }

  setShowCost(cost) {
    this.setState({showCost: cost});
  }

  setSavedCalculator(calculator) {
    if (calculator !== undefined) {
      this.setState({
        savedCalculator: calculator
      });
    }
  }

  setHasChanges(value) {
    this.setState({
      hasChanges: value
    });
  }

  handleWindowSizeChange = () => {
    let isMobileDevice = false;
    if ( window.innerWidth <= 500) isMobileDevice = true;

    this.setState({
      width: window.innerWidth,
      isMobileDevice: isMobileDevice
    });
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    this.handleWindowSizeChange()
  }

  render() {
    return (
      <Router history={history}>
        <GAListener>
        <div className="App">
          <MainNavBar />

          <Route exact path="/" render={(props) => (
            <CalculatorPage
              {...props}
              isMobileDevice={this.state.isMobileDevice}
              showCost={this.state.showCost}
              setShowCost={this.setShowCost}
              setCalcTotals={this.setCalcTotals}
              setSavedCalculator={this.setSavedCalculator}
              savedCalculator={this.state.savedCalculator}
              addSubscriptionToCalc={this.addSubscriptionToCalc}
              removeSubscriptionFromCalc={this.removeSubscriptionFromCalc}
              hasChanges={this.state.hasChanges}
              setCalculatorKey={this.setCalculatorKey}
              updateCalcTotals = {this.updateCalcTotals}
              calculatorKey={this.state.calculatorKey}
              setHasChanges={this.setHasChanges}
              monthlyCost={this.state.monthlyCost}
              firstYearCost={this.state.firstYearCost}
              annualCost={this.state.annualCost}
              setFooterCount={this.setFooterCount}
              setCalcRowCount={this.setCalcRowCount}
              calcRowCount={this.state.calcRowCount}
              />)} />
          <Route path="/calculator/:key?" render={(props) => (
            <CalculatorPage
              {...props}
              isMobileDevice={this.state.isMobileDevice}
              showCost={this.state.showCost}
              setShowCost={this.setShowCost}
              setCalcTotals={this.setCalcTotals}
              setSavedCalculator={this.setSavedCalculator}
              savedCalculator={this.state.savedCalculator}
              addSubscriptionToCalc={this.addSubscriptionToCalc}
              removeSubscriptionFromCalc={this.removeSubscriptionFromCalc}
              hasChanges={this.state.hasChanges}
              setCalculatorKey={this.setCalculatorKey}
              updateCalcTotals = {this.updateCalcTotals}
              calculatorKey={this.state.calculatorKey}
              setHasChanges={this.setHasChanges}
              monthlyCost={this.state.monthlyCost}
              firstYearCost={this.state.firstYearCost}
              annualCost={this.state.annualCost}
              setFooterCount={this.setFooterCount}
              setCalcRowCount={this.setCalcRowCount}
              calcRowCount={this.state.calcRowCount}
            />)} />

          <Route path="/subscriptions" render={(props) => (
              <SubscriptionsMobilePage
                {...props}
                isMobileDevice={this.state.isMobileDevice}
                addSubscriptionToCalc={this.addSubscriptionToCalc}
                calculatorKey={this.state.calculatorKey}
                setFooterCount={this.setFooterCount}
                footerCount={this.state.footerCount}
                calcRowCount={this.state.calcRowCount}
              />)} />

          <Route path="/subscription/:key?" render={(props) => (
            <SubscriptionDetailPage
              {...props}
              isMobileDevice={this.state.isMobileDevice}
              calculatorKey={this.state.calculatorKey}
            />)} />

          <Route path="/add_subscription" render={(props) => (
            <SubscriptionMobileForm
              {...props}
              isMobileDevice={this.state.isMobileDevice}
              displayMainFooter={this.displayMainFooter}
              addSubscriptionToCalc={this.addSubscriptionToCalc}
            />)} />

          <Route path="/disclaimer" render={(props) => <DisclaimerPage {...props} isMobileDevice={this.state.isMobileDevice} />} />

          <Route path="/share" render={(props) => (
              <SharePage
                {...props}
                isMobileDevice={this.state.isMobileDevice}
                calculatorKey={this.state.calculatorKey}
              />)} />

          <Route path="/feedback" render={(props) => (
            <FeedbackPage
              {...props}
              isMobileDevice={this.state.isMobileDevice}
              calculatorKey={this.state.calculatorKey}
            />)} />

          {this.state.isMobileDevice && this.state.showMainFooter ?
            (
              <MainFooter
                isMobileDevice={this.state.isMobileDevice}
                monthlyCost={this.state.monthlyCost}
                firstYearCost={this.state.firstYearCost}
                annualCost={this.state.annualCost}
                showCost={this.state.showCost}
                footerCount={this.state.footerCount}
              />
            ) : ("")
          }
        </div>
      </GAListener>
      </Router>
    );
  }
}

export default App;
