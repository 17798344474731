import React, { Component } from "react";
import {Link, withRouter} from 'react-router-dom';
import * as RestApi from "../modules/RestApi";
import Utils from "../modules/utils";
import {isEqual} from "../modules/Utilities";
import axios from 'axios';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Moment from "react-moment";
import IdleTimer from "react-idle-timer";
import NumberFormat from "react-number-format";
import CalculatorMobileToolbar from "../components/Calculator/CalculatorMobileToolbar";
import CalculatorMobileTabbar from "../components/Calculator/CalculatorMobileTabbar";
import CalculatorMobileRow from "../components/Calculator/CalculatorMobileRow";
import CalculatorToolbar from "../components/Calculator/CalculatorToolbar";
import CalculatorRow from "../components/Calculator/CalculatorRow";
import Feedback from "../components/Calculator/Feedback";
import SubscriptionModal from "../components/Subscriptions/SubscriptionModal";
import ShareModal from "../components/Calculator/ShareLinkModal";

class CalculatorPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      serverError: "",
      serverIssue: false,
      saveBookmarkMsg: "\u00a0",
      saveTimeMsg: "\u00a0",
      showSubscriptionModal: false,
      showShareModal: false,
      showSubscriptionMobileForm: false,
      calcRowCount: 0
    };

    this.showsubscriptionPrice = this.showsubscriptionPrice.bind(this);
    this.showMonthlyCost = this.showMonthlyCost.bind(this);
    this.showFirstYearCost = this.showFirstYearCost.bind(this);
    this.showAnnualCost = this.showAnnualCost.bind(this);
    this.showSubscriptionModal = this.showSubscriptionModal.bind(this);
    this.saveCalculator = this.saveCalculator.bind(this);
    this.createNewCalculator = this.createNewCalculator.bind(this);
    this.handleShowShareModal = this.handleShowShareModal.bind(this);
    this.handleCloseShareModal = this.handleCloseShareModal.bind(this);
    this.showShareModal = this.showShareModal.bind(this);
  }

  showSubscriptionModal(value) {
    if (value === undefined) value = true;
    this.setState({ showSubscriptionModal: value });
  }

  showShareModal(value) {
    this.setState({ showShareModal: value});
  }

  createNewCalculator() {
    this.props.setSavedCalculator([]);
    this.props.setCalculatorKey("");
    this.props.setCalcTotals({
      monthlyCost: 0,
      firstYearCost: 0,
      annualCost: 0,
    });
    this.setState({
      lastSaveDateTime: "",
      saveBookmarkMsg: "\u00a0",
      saveTimeMsg: "\u00a0",
      calculatorSaved: false,
      calculatorRows: [],

    }, () => {
      this.props.history.push('/');
    });
  }

  saveCalculator() {
    const requestSubscriptionsUrl = RestApi.API_BASE + RestApi.PATH_CALCULATOR;
    if (this.props.hasChanges) {
      console.log("Saving Changes to Calculator");
      const calc = {
        key: this.props.calculatorKey,
        calculator: this.props.savedCalculator
      };

      axios({
        method: 'post',
        url: requestSubscriptionsUrl,
        withCredentials: true,
        data: calc
      })
        .then((result) => {
          console.log("Calculator Saved");
          this.setState({
            lastSaveDateTime: new Date(result.data.updateDateTime),
            saveBookmarkMsg: "You can bookmark this page, as the calculator has been saved.",
            saveTimeMsg: "Last Saved At ",
            calculatorSaved: true,
          })
        })
        .catch((error) => {
          console.log(error);
          this.setState({isLoading: false});
          if (error.response || !error.status) {
            this.setState({
              serverIssue: true,
              serverError: "Server error, please try again."
            });
          }
        });

      this.props.setHasChanges(false);
    }
  }

  getCalculatorKey() {
    const key = Utils.getPropertySafely(() => this.props.match.params.key);
    if (key !== undefined && this.props.calculatorKey !== key) {
      this.props.setCalculatorKey(key);
      this.setState({
        calculatorKey: key
      }, ()=> {
        this.getCalculator();
      });

    } else {

      if (this.props.calculatorKey === "") {
        console.log("New Calculator Being Created");
        const requestCalculatorKey = RestApi.API_BASE + RestApi.PATH_CALCULATOR_KEY;
        axios(requestCalculatorKey, {
          withCredentials: true,
        })
        .then((result) => {
          // console.log(result.data);
          if (result.data !== null) {
            console.log(`Calculator key ${key} is valid.`);
            this.props.setCalculatorKey(result.data);
            console.log("calculator key=" + JSON.stringify(this.props.calculatorKey, null, 4));
            if (this.props.history.location.pathname.indexOf('/calculator/') > -1) {
              this.props.history.push(this.props.calculatorKey);
            } else {
              this.props.history.push('calculator/' + this.props.calculatorKey);
            }
          } else {
            this.props.setCalculatorKey(null);
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({isLoading: false});
          if (error.response || !error.status) {
            this.setState({
              serverIssue: true,
              serverError: "Server error, please try again."
            });
          }
        });
      }
    }
  }

  getCalculator() {
    if (this.props.calculatorKey !== "") {
      const requestSubscriptionsUrl = RestApi.API_BASE + RestApi.PATH_CALCULATOR + '/' + this.props.calculatorKey;
      console.log(`Fetching Calculator With Key: ${this.props.calculatorKey}`);

      axios(requestSubscriptionsUrl, {
        withCredentials: true,
      })
        .then((result) => {
          // console.log(result.data);
          if (result.data != null && result.data !== "NotFound") {
            this.props.setSavedCalculator(result.data.calculator);
            this.setState({
              saveTimeMsg: "Last Saved At ",
              saveBookmarkMsg: "You can bookmark this page, as the calculator has been saved.",
              lastSaveDateTime: new Date(result.data.updateDateTime),
              calculatorSaved: true
            }, () => {
              this.createCalcRows();
              this.props.updateCalcTotals();
            });
          }
        })
        .then(() => {
          console.log(`Received Calculator With ${this.props.savedCalculator.length} Rows`);
        })
        .catch((error) => {
          console.log(error);
          if (error.response || !error.status) {
            this.setState({
              serverIssue: true,
              serverError: "Server error, please try again."
            });
          }
        });
    }
  }

  showsubscriptionPrice() {
    this.props.setShowCost("Published");
  }

  showMonthlyCost() {
    this.props.setShowCost("Monthly");
  }

  showFirstYearCost() {
    this.props.setShowCost("First Year");
  }

  showAnnualCost() {
    this.props.setShowCost("Annual");
  }

  handleCloseShareModal() {
    this.setState({ showShareModal: false });
  }

  handleShowShareModal() {
    this.setState({ showShareModal: true });
  }

  createIntroRow() {
    if (this.props.isMobileDevice) {
      return (
        <div key="addASubscription" className="row py-2 gray-bottom-border">
          <div className="col-12 text-center">
            <Link to={"/subscriptions"}>
              <strong className="primary-link">Add a Subscription </strong>
            </Link>
            to start using the calculator.
            <div className="pt-3 text-center">
              Try adding the subscriptions that you are currently subscribed to. You might be surprised how much you
              spend per year.
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <tr className="row" key="none">
          <td className="col-md-12 text-left">
            <strong className="primary-link" onClick={this.showSubscriptionModal}>Add a Subscription</strong> to start using the calculator.
          </td>
        </tr>
      )
    }
  }

  createCalcRows() {
    let calculatorRows = [];
    if (this.props.savedCalculator.length > 0) {
      let index = 0;
      for (let row in this.props.savedCalculator) {
        let subscription = this.props.savedCalculator[row];
        if (this.props.isMobileDevice) {
          calculatorRows.push(
            <CalculatorMobileRow
              key={'subscription-' + index + '-' + subscription.id}
              removeSubscriptionFromCalc={this.props.removeSubscriptionFromCalc}
              id={subscription.id}
              index={index}
              subscription={subscription}
              showCost={this.props.showCost}
            />
          );
        } else {
          calculatorRows.push(
            <CalculatorRow
              key={'subscription-' + index + '-' + subscription.id}
              removeSubscriptionFromCalc={this.props.removeSubscriptionFromCalc}
              id={subscription.id}
              index={index}
              subscription={subscription}
              showCost={this.props.showCost}
            />
          )
        }
        index++;
      }
    } else {
      const introRow = this.createIntroRow();
      calculatorRows.push(introRow)
    }

    return calculatorRows;
  }

  componentDidMount() {
    this.getCalculatorKey();
  }

  componentDidUpdate(prevProps) {
    if (this.props.savedCalculator !== undefined && !isEqual(this.props.savedCalculator, prevProps.savedCalculator)) {
      this.setState({
        calculatorRows: []
      }, () => {
        this.createCalcRows();
        this.props.setFooterCount(this.props.savedCalculator.length);
        this.props.setCalcRowCount(this.props.savedCalculator.length);
      })
    }
  }

  render() {
    if (this.props.isMobileDevice) {

        return (
          <CSSTransition
            key={"detailFade"}
            in={!this.state.showSubscriptionView}
            timeout={500}
            classNames="calc"
          >
            <IdleTimer
              ref="saveTimer"
              timeout={3000}
              startOnLoad={false}
              onIdle={this.saveCalculator}
            >
              <div className="calc-container container-fluid">

                <CalculatorMobileToolbar
                  createNewCalculator={this.createNewCalculator}
                  calculatorKey={this.props.calculatorKey}
                />

                <div className="row status">
                  <div className="col-12 px-0 text-center my-2">
                    {this.state.serverIssue &&
                    <div className="red pt-3"><strong>{this.state.serverError}</strong></div>
                    }
                    <div className="text-muted">
                      <CSSTransition
                        key={"saveMsg"}
                        in={this.state.calculatorSaved}
                        timeout={{enter: 250, exit: 0}}
                        classNames="fade"
                      >
                        <div className="small-text-muted" key="saveMessage">
                          {this.state.saveBookmarkMsg}
                        </div>
                      </CSSTransition>
                    </div>
                  </div>
                </div>

                <CalculatorMobileTabbar
                  showCost={this.props.showCost}
                  setShowCost={this.props.setShowCost}
                  showsubscriptionPrice={this.showsubscriptionPrice}
                  showMonthlyCost={this.showMonthlyCost}
                  showFirstYearCost={this.showFirstYearCost}
                  showAnnualCost={this.showAnnualCost}
                />

                <div className="row calc-rows">
                  <div className="col-12">
                    <div className="container-fluid">
                      {this.createCalcRows()}
                    </div>
                  </div>
                </div>
              </div>
            </IdleTimer>
          </CSSTransition>
        )

    } else {

      return (
        <IdleTimer
          ref="saveTimer"
          timeout={3000}
          startOnLoad={false}
          onIdle={this.saveCalculator}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 pt-2">
                <div className="text-left"><strong>
                  Find out the costs of popular
                  subscription services or add subscriptions that you're currently pay for.<br/> You might be surprised how much you spend per year.</strong>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-3 text-left">
                {this.state.serverIssue &&
                <div className="red pt-3"><strong>{this.state.serverError}</strong></div>
                }
                <div className="text-left text-muted">
                  <CSSTransition
                    key={"saveMsg"}
                    in={this.state.calculatorSaved}
                    timeout={{ enter: 250, exit: 0 }}
                    classNames="fade"
                  >
                    <small key="saveMessage">
                      {this.state.saveBookmarkMsg}
                    </small>
                  </CSSTransition>
                </div>
              </div>
              <div className="col-md-6 pb-3">
                <div className="text-right text-muted">
                  <CSSTransition
                    key={"saveTime"}
                    in={this.state.calculatorSaved}
                    timeout={{ enter: 250, exit: 0 }}
                    classNames="fade"
                  >
                    <small>
                      {this.state.saveTimeMsg}
                      {this.state.lastSaveDateTime !== "" &&
                      <Moment format="h:mm:ss A, MMMM D, YYYY">
                        {this.state.lastSaveDateTime}
                      </Moment>
                      }
                    </small>
                  </CSSTransition>
                </div>
              </div>
            </div>
            <CalculatorToolbar
              createNewCalculator={this.createNewCalculator}
              showSubscriptionModal={this.showSubscriptionModal}
              showShareModal={this.showShareModal}
            />
          </div>
          <div className="container-fluid">
            <table className="table">
              <thead className="thead-light">
              <tr className="row">
                <th className="col-md-3 text-left table-row-label">Subscription Name</th>
                <th className="col-md-2 text-left table-row-label">Category</th>
                <th className="col-md-1 text-left table-row-label">Has Trial</th>
                <th className="col-md-1 text-left table-row-label">Shipping Fees</th>
                <th className="col-md-1 text-right table-row-label">Published Price<br/><small>(Frequency)</small></th>
                <th className="col-md-1 text-right table-row-label">Monthly Cost</th>
                <th className="col-md-1 text-right table-row-label">1st Year Cost<br/></th>
                <th className="col-md-1 text-right table-row-label">Annual Cost<br/><small>(2 Year+)</small></th>
                <th className="col-md-1 text-right table-row-label" />
              </tr>
              </thead>

               <tbody>
                {this.createCalcRows()}
              </tbody>

              <tfoot key="footer">
              <tr className="row">
                <td className="col-md-4 text-left">
                  <div className="">
                    <strong>{this.props.calcRowCount} Subscription{this.props.calcRowCount > 1 ? "s" : ""}</strong>
                  </div>
                </td>
                <td className="col-md-4 text-right">
                  <strong>Total Subscription Costs</strong>
                </td>
                <td className="col-md-1 text-right">
                  <strong>
                    <NumberFormat
                      value={this.props.monthlyCost}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={"$"}
                    />
                  </strong>
                  <div className="small-text-muted">Monthly</div>
                </td>
                <td className="col-md-1 text-right">
                  <strong>
                    <NumberFormat
                      value={this.props.firstYearCost}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={"$"}
                    />
                  </strong>
                  <div className="small-text-muted">1st Year</div>
                </td>
                <td className="col-md-1 text-right">
                  <strong>
                    <NumberFormat
                      value={this.props.annualCost}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={"$"}
                    />
                  </strong>
                  <div className="small-text-muted">Annually</div>
                </td>
                <td className="col-md-1 text-right" />
              </tr>
              </tfoot>
            </table>
          </div>
          <div className="container-fluid mt-5 navbar-background">
            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="text-left text-bottom dark-medium-text light-gray small-text mb-3">
                  Disclaimer: The content and prices above are obtained from the Subscription Provider's website. The information listed above is for informational purposes only and <strong>should not</strong> be considered an offer. We are no way responsible for the accuracy, timeliness or completeness of such content and prices. Prices change constantly and additional charges (e.g., services charges, shipping fees, local taxes and other fees) may apply, so you should always check whether the price listed on the subscription provider's web site is the one you expect.
                </div>
              </div>
              <div className="col-md-5 offset-md-1 text-right mt-3">
                <Feedback />
              </div>
            </div>
            <SubscriptionModal
              addSubscriptionToCalc={this.props.addSubscriptionToCalc}
              show={this.state.showSubscriptionModal}
              showSubscriptionModal={this.showSubscriptionModal}
            />
            <ShareModal
              show={this.state.showShareModal}
              showShareModal={this.showShareModal}
              shareURL={RestApi.CALCULATOR_BASE_URL + RestApi.PATH_CALCULATOR + '/' + this.state.calculatorKey}
            />
          </div>
        </IdleTimer>
      );
    }
  }
}
export default withRouter(CalculatorPage)
