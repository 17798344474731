import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SubscriptionMobileDetailToolbar extends Component {

  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    // const location = {
    //   pathname: '/subscription/' + this.props.subscription.id,
    // }
    // this.props.history.push(location);
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="row toolbar px-0">
        <div className="col-7 text-left">
          <Link to={'/calculator/' + this.props.calculatorKey}>
            <button
              id="btnAddSubscription"
              className="btn btn-primary btn-sm d-print-none"
              type="button">
              <FontAwesomeIcon icon="chevron-left"/> Back
            </button>
          </Link>
        </div>
        <div className="col-5 text-right">
          <a
            href={this.props.subscription.subscriptionURL}
            target="window"
            className="d-print-none"
          >
            <div
              className="btn btn-secondary btn-sm d-print-none">
              Visit Site
            </div>
          </a>
        </div>
      </div>
    )
  }

}
export default withRouter(SubscriptionMobileDetailToolbar)