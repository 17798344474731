import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';

class CalculatorRow extends Component {

  constructor(props) {
    super(props);
    this.handleRemoveSubscription = this.handleRemoveSubscription.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  handleRemoveSubscription() {
      this.props.removeSubscriptionFromCalc(this.props.subscription);
  }
  render() {

    if (this.props.subscription != null) {
      return (
        <tr className="row" key={this.props.id}>
          <td className="col-md-3 text-left">
            <div className="container ml-0 pl-0">
              <div className="row">
                <div className="col-md-8 text-left table-row-label">
                  {this.props.subscription.companyName}
                </div>
                <div className="col-md-4 mt-1 text-right small-text-muted ">
                  {this.props.subscription.subscriptionURL !== "" &&
                      <a
                        href={this.props.subscription.subscriptionURL}
                        target="window"
                        className="d-print-none"
                      >
                        Visit <FontAwesomeIcon icon="external-link-alt" />
                      </a>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-11 text-left table-row-label">
                  {this.props.subscription.subscriptionName} - {this.props.subscription.subscriptionPriceLabel}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-left small-text-muted">
                  {this.props.subscription.subscriptionDescription}
                </div>
              </div>
            </div>
          </td>
          <td className="col-md-2 text-left">
            <div className="category-name">
              {this.props.subscription.category1 !== undefined && this.props.subscription.category1 !== "" &&
                <span>{this.props.subscription.category1}{this.props.subscription.category2 !== "" ? ',' : ''}<br/>
                  {this.props.subscription.category2}</span>
              }
            </div>
          </td>
          <td className="col-md-1 text-left">
            <div className="category-name">
              { this.props.subscription.trialYN ? "Yes" : "No" }
            </div>
          </td>
          <td className="col-md-1 text-left">
            <div className="category-name">
              { this.props.subscription.shippingFeeYN ? "Yes" : "No" }
            </div>
          </td>
          <td className="col-md-1 text-right">
            <div>
              <NumberFormat
                value={this.props.subscription.subscriptionPrice}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"$"}
              />
            </div>
            <div className="small-text-muted">{this.props.subscription.subscriptionPriceLabel}<br/>
            {this.props.subscription.subscriptionDetailedPriceLabel}</div>
          </td>
          <td className="col-md-1 text-right">
            <div>
              {this.props.subscription.monthlyCost !== undefined &&
                <NumberFormat
                  value={this.props.subscription.monthlyCost}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                />
              }
              {this.props.subscription.monthlyCost === null &&
                  <span className="red">Missing</span>
              }
            </div>
            <div className="small-text-muted">
              {this.props.subscription.monthlyCostLabel}<br/>
              {this.props.subscription.monthlyDetailedCostLabel}
            </div>
          </td>
          <td className="col-md-1 text-right">
            <div>
              {this.props.subscription.firstYearCost !== undefined &&
                <NumberFormat
                  value={this.props.subscription.firstYearCost}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                />
              }
              {this.props.subscription.firstYearCost === null &&
                  <span className="red">Missing</span>
              }
            </div>
            <div className="small-text-muted">
              {this.props.subscription.firstYearCostLabel}<br/>
              {this.props.subscription.firstYearDetailedCostLabel}
            </div>
          </td>
          <td className="col-md-1 text-right">
            <div>
              {this.props.subscription.annualCost !== undefined &&
                <NumberFormat
                  value={this.props.subscription.annualCost}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                />
              }
              {this.props.subscription.annualCost === null &&
                  <span className="red">Missing</span>
              }
            </div>
            <div className="small-text-muted">
              {this.props.subscription.annualCostLabel}<br/>
              {this.props.subscription.annualDetailedCostLabel}<br/>
            </div>
          </td>
          <td className="col-md-1 d-print-none" onClick={this.handleRemoveSubscription} >
            <span className="secondary-link-text">
              <FontAwesomeIcon icon="trash" />
            </span>
          </td>
        </tr>
      );
    } else {
      return ("");
    }

  }
}

CalculatorRow.propTypes = {
  id: PropTypes.any.isRequired,
}

export default CalculatorRow;
