class Utils {
  static getPropertySafely(fn) {
    try {
      return fn();
    } catch (e) {
      return undefined;
    }
  }
}
export default Utils;

