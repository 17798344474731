import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";

class DisclaimerPage extends Component {

  render() {
    return (
        <div className="disclaimer-page container-fluid">
          <div className="row">
            <div className="col-12 text-center my-2">
              <div className="text-left dark-medium-text">
                <h2>Disclaimer</h2>
                The content and prices used by this calculator were obtained from the Subscription Provider's website. The information listed provided is for informational purposes only and <strong>should not</strong> be considered an offer. We are no way responsible for the accuracy, timeliness or completeness of such content and prices. Prices change constantly and additional charges (e.g., services charges, shipping fees, local taxes and other fees) may apply, so you should always check whether the price listed on the subscription provider's web site is the one you expect.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center my-2">
              <Link to={`/calculator/`}><div className={"btn btn-primary btn-md"}>OK</div></Link>
            </div>
          </div>
        </div>
    )
  }

}
export default withRouter(DisclaimerPage)