import React, { Component } from "react";

class SubscriptionsMobileToolbar extends Component {

  constructor(props) {
    super(props);
    this.showCalculator = this.showCalculator.bind(this);
  }

  showCalculator() {
    this.props.setFooterCount(this.props.calcRowCount);
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="row light-gray-background px-2 pt-2 pb-1">
        <div className="col-10 pr-1 pt-2 table-row-label text-left">
          Select subscriptions then click Done.
        </div>
        <div className="col-1 pl-0">
            <button
              onClick={this.showCalculator}
              id="btnCloseSubscriptionView"
              className="btn btn-primary btn-sm d-print-none"
              type="button">
              Done
            </button>
        </div>
      </div>
    );
  }
}
export default SubscriptionsMobileToolbar;
