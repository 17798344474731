import React, {Component} from "react";

class CalculatorMobileTabbar extends Component {

  render() {

    return (
      <div className="row gray-bottom-border tabbar">
        <div className="col-12 px-0 mt-2">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <div className={"nav-link disabled pr-1 nav-link-text "}>
                Costs:
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={this.props.showsubscriptionPrice}
                className={"nav-link nav-link-text px-1 mx-0 " + (this.props.showCost === 'Published' ? "active" : "")}
              >
                Published
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={this.props.showMonthlyCost}
                className={"nav-link nav-link-text px-1 mx-0 " + (this.props.showCost === 'Monthly' ? "active" : "")}
              >
                Monthly
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={this.props.showFirstYearCost}
                className={"nav-link nav-link-text px-1 mx-0 " + (this.props.showCost === 'First Year' ? "active" : "")}
              >
                1st Year
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={this.props.showAnnualCost}
                className={"nav-link nav-link-text px-1 mx-0 " + (this.props.showCost === 'Annual' ? "active" : "")}
              >
                Annual (2yr+)
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default CalculatorMobileTabbar