import React, { Component } from 'react';
import SubscriptionsMobileRow from './SubscriptionsMobileRow';

class SubscriptionsMobileList extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    let subscriptionRows = [];
    if (this.props.subscriptions.length > 0) {
      let index = 0;
      for (let row in this.props.subscriptions) {
        let subscription = this.props.subscriptions[row];
        subscriptionRows.push(
          <SubscriptionsMobileRow
            key={'subscription-' + subscription.id}
            id={subscription.id}
            index={index}
            subscription={subscription}
            addSubscriptionToCalc={this.props.addSubscriptionToCalc}
            />
        );
        index++;
      }

    } else {
      if (this.props.loading) {
        subscriptionRows.push(
          <div key="subscription-add-view" className="row">
            <div className="col-md-12">
              <div className="category-name">
                Loading...
              </div>
            </div>
          </div>
        );

      } else {
        subscriptionRows.push(
          <div key="subscription-add-view" className="row">
            <div className="col-md-12">
              <div className="category-name">
                No subscriptions match your search criteria.
              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <div className="container subscription-rows">
        <form className="">
          {subscriptionRows}
        </form>
      </div>
    );
  }
}
export default SubscriptionsMobileList;
