import React, {Component} from "react";
import * as RestApi from "../../modules/RestApi";
import axios from "axios";

class Feedback extends Component {

  constructor(props) {
    super(props);
    let maxCommentCharacters = 500;
    this.state = {
      maxCommentCharacters: maxCommentCharacters,
      commentChars: maxCommentCharacters,
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.saveComment = this.saveComment.bind(this);
  }

  handleCommentChange(e) {
    this.setState({
      comment: e.target.value,
      commentChars: this.state.maxCommentCharacters - e.target.value.length,
    });
  }

  saveComment() {
    const requestCommentUrl = RestApi.API_BASE + RestApi.PATH_COMMENT;
    if (this.state.comment.length > 4 && this.state.comment.length <=500) {
      console.log("Saving Changes to Calculator");
      const comment = {
        key: this.state.requestKey,
        comment: this.state.comment
      }

      axios({
        method: 'post',
        url: requestCommentUrl,
        withCredentials: true,
        data: comment
      })
        .then((result) => {
          console.log("Comment Saved");
          this.setState({
            commentSavedMsg: "Thank you for submitting your feedback",
            comment: '',
            commentChars:500
          })
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
          if (error.response || !error.status) {
            this.setState({ commentSavedMsg: "Server error, please try again." });
          }
        });
    } else if (this.state.comment.length > 500) {
      this.setState({
        commentSavedMsg: "Please limit your feedback to " + this.state.maxCommentCharacters + " characters.",
      });
    } else {
      this.setState({
        commentSavedMsg: "Please provide feedback before sending.",
      });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="dark-medium-text text-left light-gray">
            Please provide feedback. For example, what subscription
            providers you would like to see added or additional features you think would be helpful.
          </div>
        </div>
        <form>
          <div className="row">
            <textarea
              className="form-control col-md-12"
              id="comment" rows="3"
              onChange={this.handleCommentChange}
              value={this.state.comment} >
            </textarea>
          </div>
          <div className="row pb-3">
            <div className="col-md-10 text-left pl-0">
              <small className={"form-text " + (this.state.commentChars >= 0 ? 'white' : 'red')}>
                {this.state.commentChars} Characters Remaining
              </small>
              <span className="white">{this.state.commentSavedMsg}&nbsp;</span>
            </div>
            <div className="col-md-2 text-left">
              <button
                onClick={this.saveComment}
                type="button"
                className="btn btn-primary mt-3">
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
export default Feedback