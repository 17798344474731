/* eslint no-unused-vars: 0 */
export const PATH_BASE = process.env.REACT_APP_PATH_BASE;
export const CALCULATOR_BASE_URL = process.env.REACT_APP_CALCULATOR_BASE_URL;
export const API_BASE = process.env.REACT_APP_API_BASE;

export const PATH_SUBSCRIPTIONS = 'subscriptions';
export const PATH_SUBSCRIPTION = 'subscription';
export const PATH_SUBSCRIPTION_ID = 'subscription_id';
export const PATH_CALCULATOR = 'calculator';
export const PATH_CALCULATOR_KEY = 'calckey';
export const PATH_COMMENT = 'comment';
