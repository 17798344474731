import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import axios from 'axios';
import * as RestApi from "../modules/RestApi";

class FeedbackPage extends Component {

  constructor(props) {
    super(props);
    let maxCommentCharacters = 500;
    this.state = {
      maxCommentCharacters: maxCommentCharacters,
      commentChars: maxCommentCharacters,
      commentSavedMsg: "",
      comment: "",
    };

    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.saveComment = this.saveComment.bind(this);
  }

  handleCommentChange(e) {
    this.setState({
      comment: e.target.value,
      commentChars: this.state.maxCommentCharacters - e.target.value.length,
    });
  }

  saveComment() {
    const requestCommentUrl = RestApi.API_BASE + RestApi.PATH_COMMENT;
    if (this.state.comment.length > 4 && this.state.comment.length <=500) {
      console.log("Saving Changes to Calculator");
      const comment = {
        key: this.props.calculatorKey,
        comment: this.state.comment
      };

      axios({
        method: 'post',
        url: requestCommentUrl,
        withCredentials: true,
        data: comment
      })
        .then((result) => {
          console.log("Comment Saved");
          this.setState({
            commentSavedMsg: "Thank you for submitting your feedback",
            comment: '',
            commentChars:500
          })
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
          if (error.response || !error.status) {
            this.setState({ commentSavedMsg: "Server error, please try again." });
          }
        });
    } else if (this.state.comment.length > 500) {
      this.setState({
        commentSavedMsg: "Please limit your feedback to " + this.state.maxCommentCharacters + " characters.",
      });
    } else {
      this.setState({
        commentSavedMsg: "Please provide MORE feedback above before hitting send. ",
      });
    }
  }

  render() {

    return (
      <div className="share-page container-fluid">
        <div className="row">
          <div className="col-12 text-center my-2">

              <div className="container">
                <div className="row">
                  <div className={"col-12 text-left"}>
                    <h2>Feedback</h2>
                    <div className="dark-medium-text text-left">
                      Please provide feedback below. For example, let us know what subscription
                      providers we should add to the calculator or additional features you think would be helpful.
                    </div>
                  </div>
                </div>
                <form>
                <div className="row">
                  <div className={"col-12 text-left mt-3"}>
                    <textarea
                      className="form-control"
                      id="comment" rows="4"
                      onChange={this.handleCommentChange}
                      value={this.state.comment} />
                    <small className={"form-text " + (this.state.commentChars >= 0 ? '' : 'red')}>
                      {this.state.commentChars} Characters Remaining
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-2 text-left">
                    <span><strong>{this.state.commentSavedMsg}&nbsp;</strong></span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-3 text-left">
                    <button
                      onClick={this.saveComment}
                      type="button"
                      className="btn btn-primary">
                      Send
                    </button>&nbsp;
                    <Link to={`/calculator/${this.props.calculatorKey}`}>
                      <button
                        type="button"
                        className="btn btn-secondary ml-1">
                        Go Back to Calculator
                      </button>
                    </Link>
                  </div>
                </div>
                </form>
              </div>

          </div>
        </div>
      </div>
    )
  }

}
export default withRouter(FeedbackPage)